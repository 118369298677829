<template>
  <div>

    <div style="color: rgba(2, 181, 255, 1);font-size: calc(100vw * 16 / 1920);line-height:4.6vh;text-align: center;padding-bottom: 5vh;padding-top: 2vh;width: 60%;margin-left: 20%">
      <div style="display: flex; height: 4.6vh;justify-content: space-around;">
        <div class="btn" v-bind:class=" btn === 0?'lightBtn':''" @click="chooseBtn(0)">土地资源基本情况统计表</div>
        <div class="btn" v-bind:class=" btn === 1?'lightBtn':''" @click="chooseBtn(1)">土地资源出租情况汇总表</div>
        <div class="btn" v-bind:class=" btn === 2?'lightBtn':''" @click="chooseBtn(2)">建设用地汇总表</div>

      </div>
    </div>
    <div  v-show = "btn === 0">
      <div class="query light" style="width: 70px;text-align: center;" @click="exportList()">导出</div>
      <div style="text-align: right;color: #FFFFFF">单位：亩</div>

      <el-table
          :data="totalList"
          :header-cell-style="headerStyle"
          :cell-style="rowStyle"
          @row-click="handleRowClick"
      >
        <el-table-column prop="classification" label="分类" ></el-table-column>
        <el-table-column  label="镇保土地">
          <el-table-column prop="selfUseArea" label=自用></el-table-column>
          <el-table-column prop="rentOutArea" label=出租></el-table-column>
          <el-table-column prop="inIdleArea" label=闲置></el-table-column>
          <el-table-column prop="sumArea" label=小计></el-table-column>
        </el-table-column>
        <el-table-column prop="stockHouseArea" label=集中居住、减量化土地></el-table-column>
        <el-table-column prop="constructArea" label=建设用地></el-table-column>
        <el-table-column prop="unConstructArea" label=非建设用地></el-table-column>
        <el-table-column prop="transferArea" label=转制集体土地数量></el-table-column>
        <el-table-column prop="areaVacancay" label=区域内场地 ></el-table-column>
        <el-table-column prop="otherArea" label=其他土地></el-table-column>
        <el-table-column prop="totalArea" label=合计></el-table-column>
      </el-table>
    </div>
      <div  v-show = "btn === 1">
        <div class="query light" style="width: 70px;text-align: center;" @click="exportList()">导出</div>

        <div style="text-align: right;color: #FFFFFF">面积：亩，租金：万元/年</div>
        <el-table
            :data="totalList"
            :header-cell-style="headerStyle"
            :cell-style="rowStyle"
            @row-click="handleRowClick"
        >
          <el-table-column prop="classification" label="分类" ></el-table-column>
          <el-table-column  label="镇保土地">
            <el-table-column prop="burgArea" label=面积></el-table-column>
            <el-table-column prop="burgPrice" label=租金></el-table-column>
          </el-table-column>
          <el-table-column  label="建设用地">
            <el-table-column prop="constructArea" label=面积></el-table-column>
            <el-table-column prop="constructPrice" label=租金></el-table-column>
          </el-table-column>
          <el-table-column  label="非建设用地">
            <el-table-column prop="unconstructArea" label=面积></el-table-column>
            <el-table-column prop="unconstructPrice" label=租金></el-table-column>
          </el-table-column>
          <el-table-column  label="集中居住、减量化土地">
            <el-table-column prop="stockArea" label=面积></el-table-column>
            <el-table-column prop="stockPrice" label=租金></el-table-column>
          </el-table-column>
          <el-table-column  label="转制集体土地等">
            <el-table-column prop="transferArea" label=面积></el-table-column>
            <el-table-column prop="transferPrice" label=租金></el-table-column>
          </el-table-column>
          <el-table-column  label="区域内场地">
            <el-table-column prop="otherArea" label=面积></el-table-column>
            <el-table-column prop="otherPrice" label=租金></el-table-column>
          </el-table-column>
        </el-table>
      </div>

    <div  v-show = "btn === 2">
      <div class="query light" style="width: 70px;text-align: center;" @click="exportList()">导出</div>

      <div style="text-align: right;color: #FFFFFF">面积：亩，租金：万元/年</div>
      <el-table
          :data="totalList"
          :header-cell-style="headerStyle"
          :cell-style="rowStyle"
          @row-click="handleRowClick"
      >
        <el-table-column prop="manageName" label="分类" ></el-table-column>
        <el-table-column prop="reserveLand" label=储备用地></el-table-column>
        <el-table-column prop="developLand" label=待开发建设用地></el-table-column>
        <el-table-column  label="转制等其他建设用地">
          <el-table-column prop="transferLand" label=转制集体土地></el-table-column>
          <el-table-column prop="fiveToFour" label=五为四必></el-table-column>
          <el-table-column prop="other" label=出让到期国地及其他特殊类用地></el-table-column>
          <el-table-column prop="total" label=小计></el-table-column>
        </el-table-column>
        <el-table-column prop="sum" label=合计></el-table-column>

      </el-table>
    </div>
    </div>


</template>

<script>
import {
  getHousingSummary, getLandJSTotal,
  getLandRentTotal,
  getLandTotal,
  getTotalList,
  landExport, landExportJS,
  landExportRent
} from "@/api/Hosing";
export default {
  name: "userJurisdiction",
  props:{
    rowId:String
  },
  data() {
    return {
      form: {
        nickName: "",
        userName: "", // 手机号
        etName:'', // 企业
      },
      totalList: [],
      currentPage: 1,
      pageSize: 15,
      total: 0,
      btn:0
    };
  },

  methods: {
    handleRowClick(row, column, event) {
      console.log(row,'row11'); // 被点击的行数据对象，包含了行中的所有数据。可以在这里进行你需要的操作。例如：打印行数据。
      console.log(column); // 被点击的列数据对象，包含了列的属性信息。可以在这里进行你需要的操作。例如：打印列属性。
      console.log(event); // 原生的事件对象，包含了事件的一些信息。可以在这里进行你需要的操作。例如：阻止事件的默认行为。
      this.$emit('changeCard',row)
    },
    goIndex(){
      this.$router.push("/index");
    },
    headerStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    rowStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getList();
    },
    chooseBtn(e){
      this.btn = e;
      if (e===0){
        this.getList()
      }else if(e===1) {
        this.getRentList()
      }else {
        this.getJSList()
      }
    },
    exportList(){


      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        manageType: 0,
      };
      if (this.btn===0){
        landExport(data).then((res) => {
          console.log("ImageDownload`" +res.msg +"`" );
        });
      }else if(this.btn===1) {
        landExportRent(data).then((res) => {
          console.log("ImageDownload`" +res.msg +"`" );
        });
      } else {
        landExportJS(data).then((res) => {
          console.log("ImageDownload`" +res.msg +"`" );
        });

      }
    },
    getList() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        manageType: 0,
      };
      getLandTotal(data).then((res) => {
        this.totalList = res.data;
        console.log(this.totalList," res.data.data")
      });
    },
    getRentList() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        manageType: 0,
      };
      getLandRentTotal(data).then((res) => {
        this.totalList =  res.data;
      });
    },
    getJSList() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        manageType: 0,
      };
      getLandJSTotal(data).then((res) => {
        this.totalList =  res.data;
      });
    },




  },
  mounted() {
     this.getList();
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  margin-top: 2vh;
  width: 100%;
  position: relative;
  .left-title {
    position: absolute;
    left: 0;
    top: 0;
    background: url(@/assets/images/titleBg.png);
    background-size: 100% 100%;
    width: 11.1vw;
    height: 5.5vh;
    color: #fff;
    font-size: calc(100vw * 20 / 1920);
    text-align: center;
    line-height: 5.5vh;
    margin-right: 4vw;
  }
  .right-container {
    width: 81vw;
    position: absolute;
    left: 3vw;
    right: 0;
    margin: 0 auto;

    .right-top {

      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .el-form-item {
        display: flex;
        align-items: center;
        height: 4.6vh;

        .el-input__inner {
          width: 100%;
          height: 4.6vh;
          font-size: calc(100vw * 16 / 1920);
          box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
          border-radius: 6px;
          border: 0.02rem solid #286ae8;
          background: none;
          color: #fff;
          &::placeholder {
            color: #fff;
          }
        }
        .el-form-item__label {
          width: 6.5vw;
          text-align: left;
          color: #fff;
          font-size: calc(100vw * 16 / 1920);
        }
      }
      .query,
      .reset {
        width: 10vw;
        height: 100%;
        background: url(@/assets/images/updown.png);
        background-size: 100% 100%;
        color: #02b5ff;
        font-size: calc(100vw * 16 / 1920);
        line-height: 4.6vh;
        text-align: center;
        cursor: pointer;
      }
      .query {
        margin-right: 3vw;
      }
    }
  }
}
::v-deep .el-form {
  width: 65vw;
  padding-left: 10vw;
  box-sizing: border-box;
}
::v-deep .el-table {
  width: 99.9%;
  margin-bottom: 2.5vh;
  font-size: calc(100vw * 16 / 1920);
}
::v-deep .el-table__body {
  font-size: calc(100vw * 14 / 1920);
}
::v-deep .el-table__row {
  height: 4vh;
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  display: none;
}
::v-deep .el-pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1vh;
}
::v-deep .el-pagination .el-select .el-input .el-input__inner,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev,
::v-deep .el-pager li,
::v-deep .el-pager li.btn-quicknext,
::v-deep .el-pager li.btn-quickprev,
::v-deep .el-pagination__total {
  //background: #0f2455;
  border-radius: 6px;
  border: 1px solid #286AE8;
  box-shadow: inset 0px 0px 20px 21px rgba(15,53,116,0.6);
  color: #fff;
  text-align: center;
}
::v-deep .el-pager li,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  margin-right: 0.5vw;
}
::v-deep .el-pagination__total {
  margin-right: 0;
  padding: 0 0.8vw;
  box-sizing: border-box;
}
::v-deep .el-form-item__content {
  height: 4.6vh;
}
::v-deep .el-pager li.active {
  color: #409EFF;
}

.lightBtn{
  background: url(@/assets/use/btn3.png) !important;
  background-size: 100% 100% !important;
  color: #2EE6FE !important;
  width: 12vw;
  height: 100%;
  background-size: 100% 100%;
  font-size: calc(100vw * 16 / 1920);
  line-height: 4.6vh;
  text-align: center;
  cursor: pointer;

}
.btn{
  background: url(@/assets/images/updown.png);
  background-size: 100% 100%;
  color: #02b5ff;
  width: 12vw;
  height: 100%;
  font-size: calc(100vw * 16 / 1920);
  line-height: 4.6vh;
  text-align: center;
  cursor: pointer;

}
.light{
  background: url(@/assets/use/btn3.png) !important;
  background-size: 100% 100% !important;
  color: #2EE6FE !important;

}
</style>
