<template>
  <div class="container">
    <div style = "position: absolute;
    color: #ffffff;
    left: 2.5vh;
    top: -4vh;
    z-index: 999;
    font-size: calc(100vw * 14 / 1920);
    display: flex;
    align-items: center;
    cursor: pointer;"
   >
      <img
          class="label_1"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngbab5f198dfc4bdb15571a116b3d9e4cde6067c4d38c292b039ea2618876a9b7d"
      />
      <span class="text_1"  @click="goIndex()">返回首页</span>

      <div class="right-top">

          <div style="display: flex;height: 3vh;padding-left: 1vw;">
              <el-input
                  placeholder="房屋名称"
                  v-model="searchName"
                  style="width: 12vw"
              ></el-input>
            <div >
              <div class="query" style="width: 2.5vw;height: 3vh;line-height: 3vh;text-align: center;background-color: #17e0fe;color: #FFFFFF;border-radius: 10px;cursor: pointer;" @click="searchPoint()">搜索</div>
            </div>


          </div>

      </div>
    </div>

    <div style = "position: absolute;
    color: rgb(255, 255, 255);
    left: 2.5vh;
    top: -4vh;
    z-index: 999;
    font-size: calc(1vw);
    top: 87vh;
    text-align: center;
    cursor: pointer;"
         @click="goIndex()">
      <img
          class="label_1"
          referrerpolicy="no-referrer"
          src="@/assets/use/index_bot.png"
          style="width: 100vw;height: 6vh"
      />
      <span style="position: relative;top:-5vh;color: #17e0fe;
    font-size: calc(100vw * 23 / 1920);text-align: center">上海 · 浦东 · 宣桥镇</span>
    </div>

<!--    <div style = "position: absolute;-->
<!--    color: rgb(255, 255, 255);-->
<!--    left: 60vw;-->
<!--    z-index: 999;-->
<!--    font-size: calc(0.7vw);-->
<!--    top: 75vh;-->
<!--    text-align: center;-->
<!--    cursor: pointer;">-->
<!--      <div style="display: flex;">-->
<!--        <div style = "background-color: #5da460;width: 4vw;height: 2vh;"></div>-->
<!--        <div style="margin-left: 1vw;color:#5da460;">镇保土地</div>-->
<!--      </div>-->
<!--      <div style="display: flex;margin-top: 1vh;">-->
<!--        <div style = "background-color: #ff1a19;width: 4vw;height: 2vh;"></div>-->
<!--        <div style="margin-left: 1vw;color:#ff1a19;">房屋资产</div>-->
<!--      </div>-->
<!--      <div style="display: flex;margin-top: 1vh;">-->
<!--        <div style = "background-color: #bd9943;width: 4vw;height: 2vh;"></div>-->
<!--        <div style="margin-left: 1vw;color:#bd9943;">存量、建设用地</div>-->
<!--      </div>-->
<!--    </div>-->


    <div class="container_">
      <div id="container" tabindex="0" class="allmap"></div>
    </div>
    <div class="left-container">
      <div class="one-container" @click="goView('housingTotal')">
        <div class="left-title">房屋资产</div>
        <div style="
            box-sizing: border-box;
            color: #6dd400;
            display: flex;
            width: 80%;
            margin-left: 10%;
            flex-wrap: wrap;
            justify-content: space-evenly;
             font-size: calc(100vw * 14 / 1920);
          ">
          <div style="width: 40%;padding-top: 1vh;padding-right: 1vw;">
            <div style="color:#FFFFFF ">总建筑面积</div>
            <div style="display: flex;align-items: center;width: 100%;justify-content: space-between; color: #17E0FE">
              <div>{{ house.buildingArea }}平方米</div>
              <div><img src="@/assets/use/lou.png" style="width: 1.5vw"/></div>
            </div>
          </div>
          <div style="width: 40%;padding-top: 1vh;padding-right: 1vw;">
            <div style="color:#FFFFFF ">总处数</div>
            <div style="display: flex;align-items: center;width: 100%;justify-content: space-between; color: #17E0FE">
              <div>{{ house.totalNumber }}处</div>
              <div><img src="@/assets/use/lou.png" style="width: 1.5vw;"/></div>
            </div>
          </div>
          <div style="width: 40%;padding-top: 1vh;padding-right: 1vw;">
            <div style="color:#FFFFFF ">出租面积</div>
            <div style="display: flex;align-items: center;width: 100%;justify-content: space-between; color: #17E0FE">
              <div>{{ house.rentOutArea }}平方米</div>
              <div><img src="@/assets/use/lou.png" style="width: 1.5vw"/></div>
            </div>
          </div>
          <div style="width: 40%;padding-top: 1vh;padding-right: 1vw;">
            <div style="color:#FFFFFF ">空置面积</div>
            <div style="display: flex;align-items: center;width: 100%;justify-content: space-between; color: #17E0FE">
              <div>{{ house.inIdleArea }}平方米</div>
              <div><img src="@/assets/use/lou.png" style="width: 1.5vw"/></div>
            </div>
          </div>
        </div>
      </div>
      <div class="two-container" @click="goView('housingTotal')">
        <div class="left-title">房屋资产面积</div>
        <div
            style="
            width: 23vw;
            height: 20vh;
            padding-top: 1vh;
            box-sizing: border-box;">
          <div id="chartZhu" style="width: 100%; height: 100%"></div>
        </div>
      </div>
      <div class="three-container" @click="goView('housingTotal')">
        <div class="left-title">房屋租赁使用情况(面积/m²)</div>
        <div
            style="
            width: 23vw;
            height: 20vh;
            box-sizing: border-box;">
          <div id="chartZu" style="width: 100%; height: 100%"></div>
        </div>
      </div>
      <div class="four-container" @click="goView('housingTotal')">
        <div class="left-title">房屋租赁使用情况(年租金 万元/年)</div>
        <div
            style="
            width: 23vw;
            height: 20vh;
            box-sizing: border-box;">
          <div id="chartCun" style="width: 20vw; height: 20vh"></div>
          <div style="width: 30%; height: 50%;position: relative;left: 30%;bottom: 100%;">
            <div style="display: flex;justify-content: center;align-items: center;margin-top: 0.5vh;" >
              <div style="margin-left: 0.3vw; font-size: calc(100vw * 12 / 1920);color: #FFFFFF">总计:</div>
              <div style="margin-left: 0.3vw; font-size: calc(100vw * 12 / 1920);color: #FFFFFF">{{leftAmt}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="right-container">
      <div class="four-container" @click="goView('landTotal')">
        <div class="right-title">土地资源</div>
        <div style="
            box-sizing: border-box;
            color: #6dd400;
            display: flex;
            width: 80%;
            margin-left: 10%;
            flex-wrap: wrap;
            justify-content: space-evenly;
             font-size: calc(100vw * 14 / 1920);
          ">
          <div style="width: 40%;padding-top: 1vh;padding-right: 1vw;">
            <div style="color:#FFFFFF ">镇保土地</div>
            <div style="display: flex;align-items: center;width: 100%;justify-content: space-between; color: #17E0FE">
              <div>{{land.burgArea}}亩</div>
              <div><img src="@/assets/use/di.png" style="width: 1.5vw"/></div>
            </div>
          </div>
          <div style="width: 40%;padding-top: 1vh;padding-right: 1vw;">
            <div style="color:#FFFFFF ">建设用地</div>
            <div style="display: flex;align-items: center;width: 100%;justify-content: space-between; color: #17E0FE">
              <div>{{land.totalArea}}亩</div>
              <div><img src="@/assets/use/di.png" style="width: 1.5vw"/></div>
            </div>
          </div>
          <div style="width: 40%;padding-top: 1vh;padding-right: 1vw;">
            <div style="color:#FFFFFF ">储备用地</div>
            <div style="display: flex;align-items: center;width: 100%;justify-content: space-between; color: #17E0FE">
              <div>{{land.farmingArea}}亩</div>
              <div><img src="@/assets/use/di.png" style="width: 1.5vw;"/></div>
            </div>
          </div>
          <div style="width: 40%;padding-top: 1vh;padding-right: 1vw;">
            <div style="color:#FFFFFF ">转制等其他建设用地</div>
            <div style="display: flex;align-items: center;width: 100%;justify-content: space-between; color: #17E0FE">
              <div>{{land.constructArea}}亩</div>
              <div><img src="@/assets/use/di.png" style="width: 1.5vw"/></div>
            </div>
          </div>

        </div>
      </div>
      <div class="five-container" @click="goView('landTotal')">
        <div class="left-title">镇保土地 (亩)</div>
        <div
            style="
            height: 20vh;
              width: 23vw;
            padding-top: 1vh;
            box-sizing: border-box;">
          <div id="chartTu" style="width: 100%; height: 100%"></div>
        </div>
      </div>
      <div class="six-container" @click="goView('landTotal')">
        <div class="left-title">土地出租(面积/亩)</div>
        <div
            style="
            width: 23vw;
            height: 20vh;
            box-sizing: border-box;">

          <div id="chartTuZu" style="width: 100%; height: 100%"></div>

        </div>
      </div>

      <div class="seven-container" @click="goView('landTotal')">
        <div class="left-title">土地出租(年租金 万元/年)</div>
        <div style="display: flex;justify-content: center;align-items: center;" >
          <div style="margin-left: 0.3vw; font-size: calc(100vw * 12 / 1920);color: #FFFFFF">总计:</div>
          <div style="margin-left: 0.3vw; font-size: calc(100vw * 12 / 1920);color: #FFFFFF">{{rightAmt}}</div>
        </div>
        <div style="
            width: 23vw;
            height: 20vh;
            box-sizing: border-box; display: flex;justify-content: center;">
          <div style="width: 20%; height: 50%;margin-top: 1%;text-align: left">
            <div style="display: flex;justify-content: center;align-items: center;margin-top: 0.5vh;" >
              <div style="width: 1.3vw;height:1.5vh;background-color: #12E5F3;border-radius: 4px; "></div>
              <div style="margin-left: 0.3vw; font-size: calc(100vw * 12 / 1920);color: #FFFFFF">光明</div>
            </div>
            <div style="display: flex;justify-content: center;align-items: center;margin-top: 0.5vh;" >
              <div style="width: 1.3vw;height:1.5vh;background-color: #0A89FC;border-radius: 4px; "></div>
              <div style="margin-left: 0.3vw; font-size: calc(100vw * 12 / 1920);color: #FFFFFF">光辉</div>
            </div>
            <div style="display: flex;justify-content: center;align-items: center;margin-top: 0.5vh;" >
              <div style="width: 1.3vw;height:1.5vh;background-color: #09E281;border-radius: 4px; "></div>
              <div style="margin-left: 0.3vw; font-size: calc(100vw * 12 / 1920);color: #FFFFFF">三灶</div>
            </div>
            <div style="display: flex;justify-content: center;align-items: center;margin-top: 0.5vh;" >
              <div style="width: 1.3vw;height:1.5vh;background-color: #FFD04B;border-radius: 4px; "></div>
              <div style="margin-left: 0.3vw; font-size: calc(100vw * 12 / 1920);color: #FFFFFF">宣桥</div>
            </div>
            <div style="display: flex;justify-content: center;align-items: center;margin-top: 0.5vh;" >
              <div style="width: 1.3vw;height:1.5vh;background-color: #2B1B84;border-radius: 4px; "></div>
              <div style="margin-left: 0.3vw; font-size: calc(100vw * 12 / 1920);color: #FFFFFF">新安</div>
            </div>
            <div style="display: flex;justify-content: center;align-items: center;margin-top: 0.5vh;" >
              <div style="width: 1.3vw;height:1.5vh;background-color: #3A00E5;border-radius: 4px; "></div>
              <div style="margin-left: 0.3vw; font-size: calc(100vw * 12 / 1920);color: #FFFFFF">季桥</div>
            </div>
            <div style="display: flex;justify-content: center;align-items: center;margin-top: 0.5vh;" >
              <div style="width: 1.3vw;height:1.5vh;background-color: #652D91;border-radius: 4px; "></div>
              <div style="margin-left: 0.3vw; font-size: calc(100vw * 12 / 1920);color: #FFFFFF">长春</div>
            </div>
          </div>
          <div id="chartTuZuMoney" style="width: 40%; height: 100%">

          </div>
          <div style="width: 30%; height: 50%;margin-top: 1%">
            <div style="display: flex;justify-content: center;align-items: center;margin-top: 0.5vh;" >
              <div style="width: 1.3vw;height:1.5vh;background-color: #FF409E;border-radius: 4px; "></div>
              <div style="margin-left: 0.3vw; font-size: calc(100vw * 12 / 1920);color: #FFFFFF">中心</div>
            </div>
            <div style="display: flex;justify-content: center;align-items: center;margin-top: 0.5vh;" >
              <div style="width: 1.3vw;height:1.5vh;background-color: #FF884D;border-radius: 4px; "></div>
              <div style="margin-left: 0.3vw; font-size: calc(100vw * 12 / 1920);color: #FFFFFF">项埭</div>
            </div>
            <div style="display: flex;justify-content: center;align-items: center;margin-top: 0.5vh;" >
              <div style="width: 1.3vw;height:1.5vh;background-color: #AD72FF;border-radius: 4px; "></div>
              <div style="margin-left: 0.3vw; font-size: calc(100vw * 12 / 1920);color: #FFFFFF">腰路</div>
            </div>
            <div style="display: flex;justify-content: center;align-items: center;margin-top: 0.5vh;" >
              <div style="width: 1.3vw;height:1.5vh;background-color: #9CB3FF;border-radius: 4px; "></div>
              <div style="margin-left: 0.3vw; font-size: calc(100vw * 12 / 1920);color: #FFFFFF">陆桥</div>
            </div>
            <div style="display: flex;justify-content: center;align-items: center;margin-top: 0.5vh;margin-left: 0.8vw;" >
              <div style="width: 1.3vw;height:1.5vh;background-color: #FF1A19;border-radius: 4px; "></div>
              <div style="margin-left: 0.3vw; font-size: calc(100vw * 12 / 1920);color: #FFFFFF">张家桥</div>
            </div>
            <div style="display: flex;justify-content: center;align-items: center;margin-top: 0.5vh;" >
              <div style="width: 1.3vw;height:1.5vh;background-color: #A2FF2D;border-radius: 4px;margin-left: 1.3vw; "></div>
              <div style="margin-left: 0.3vw; font-size: calc(100vw * 12 / 1920);color: #FFFFFF">公司单位</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script src="//a.amap.com/jsapi_demos/static/data/weight.js"></script>

<script src="https://webapi.amap.com/maps?v=2.0&key=a36153e6de17e8577776e26b87349a66&plugin=AMap.MarkerCluster"></script>
<script type="text/javascript" src="http://webapi.amap.com/maps?v=1.4.6&key=d80a7a92446ba0532e1e9db497471994&plugin=AMap.PolyEditor,AMap.BezierCurve,AMap.ToolBar,AMap.Driving,AMap.BezierCurveEditor"></script>


<script>
import {getMap} from "@/api/home";
import {
  getAlarmToal,
  getAlarmYearly,
  getRepairYearly,
  getAlarmType,
  getRepairNum,
} from "@/api/intelligence";
import {
  getIndexHouse, getIndexHouseInfo,
  getIndexLand,getIndexLandInfo

} from "@/api/Hosing";
import {AMapManager, lazyAMapApiLoaderInstance} from "vue-amap";

let amapManager = new AMapManager();

export default {
  name: "indexOverview",

  data() {
    let self = this;
    return {
      pointsList: [
       ],
      windowWidth: 1920,
      windowHeight: 1080,
      alarmsNum: [], // 告警总数
      yearlyAlarm: [], // 年度告警排行
      repairList: [], // 维修处理年度排行
      alarmType: [], // 告警类型分布
      repairNum: [], // 维修数
      land:{},
      house:{},
      houseInfo:'',
      landInfo:'',
      superuser:false,
      ishouse:false,
      island:false,
      leftAmt:'',
      rightAmt:'',

      searchName: "",



    };
  },
  methods: {
    goIndex(){
      this.$router.push("/index");
    },
    goView(e){
      // if (e==='landTotal'){
      //   if (!this.island||!this.superuser){
      //     console.log(this.superuser,this.island,'sss')
      //     return;
      //   }
      // }
      // if (e==='houseTotal'){
      //
      //   if (!this.ishouse||!this.superuser){
      //     console.log(this.superuser,this.ishouse,'sss')
      //     return;
      //   }
      // }
      this.$router.push('/'+e);
    },
    // 年度告警排行
    goAlarmRanking() {
      this.$router.push("/alarm");
    },
    // 维修处理
    goRepair() {
      this.$router.push('/repairManagement')
    },
    nowWidthSize(val) {
      let width = document.documentElement.clientWidth;
      let size = (width * val) / this.windowWidth;
      console.log(size, "size");
      return size;
    },
    nowHeightSize(val) {
      let height = document.documentElement.clientHeight;
      let size = (height * val) / this.windowHeight;
      console.log(size, "size");
      return size;
    },


    initMap() {
      var map = new AMap.Map("container", {
        center: [121.694576,31.035697],
        mapStyle: "amap://styles/darkblue",
        zoom: 20,
      });
      var path = [//每个弧线段有两种描述方式

        //   [121.67824029922485,31.081229784561646],
        //   [121.70117855072021,31.083673911209928],
        //   [121.70928955078125,31.068755490295903],
        //   [ 121.71012103557587,31.06881062889907],
        //   [ 121.71006202697754,31.06752405316168],
        //   [  121.68719351291656,31.064224825837165],
        //   [  121.68688774108887,31.066696959567892],
        //   [ 121.68330430984497,31.067680281143947],
        //   [  121.68221533298492,31.073460536061898],
        //   [ 121.6836154460907,31.073897027529906,],
        //   [ 121.68258011341095,31.07782995501255],
        //   [  121.67835831642151,31.07977338598066],
        // [121.67824029922485,31.081229784561646],
        //
        //
        //   //第二个
        // [ 121.70117855072021,31.083673911209928],
        // [ 121.70199394226074,31.084427351111557 ],
        // [ 121.70394659042358,31.086062846439976],
        // [ 121.70315265655518,31.08778100503561 ],
        // [ 121.70970797538757,31.08806583046413],
        // [ 121.71033024787903,31.085585064198018],
        // [  121.71465396881104,31.086136351187065],
        // [  121.71664953231812,31.080191471137518],
        // [ 121.71664953231812,31.080191471137518],
        // [  121.71933174133301,31.07627701944002],
        // [ 121.7205548286438,31.07241754307113],
        // [  121.71729326248169,31.072031586817545 ],
        // [  121.71795845031738, 31.069348228604625],
        // [   121.71006202697754,31.06752405316168],
        // [  121.71012103557587,31.06881062889907],
        // [  121.70928955078125,31.068755490295903],
        // [  121.70117855072021,31.083673911209928]
        [121.7001960237,31.0708286546],
        [121.7002070365,31.0704698412],
        [121.7003030201,31.0688705985],
        [121.6968256508,31.0683805938],
        [121.6864099987,31.0668618050],
        [121.6821988928,31.0662599552],
        [121.6821657896,31.0682468825],
        [121.6809450986,31.0682661636],
        [121.6807364945,31.0682695545],
        [121.6807275719,31.0675449849],
        [121.6795118922,31.0675013048],
        [121.6794788814,31.0683878612],
        [121.6794578774,31.0689165992],
        [121.6783350541,31.0686009072],
        [121.6782890454,31.0697653304],
        [121.6782730409,31.0701881198],
        [121.6791593300,31.0703363549],
        [121.6780550691,31.0748728685],
        [121.6788864574,31.0750761756],
        [121.6787446928,31.0755101979],
        [121.6788105623,31.0755620436],
        [121.6778930594,31.0787259911],
        [121.6773910319,31.0786449915],
        [121.6775028609,31.0781130801],
        [121.6772693146,31.0780645519],
        [121.6766982317,31.0802683918],
        [121.6760365124,31.0802316741],
        [121.6756612313,31.0803103457],
        [121.6749386004,31.0806645255],
        [121.6739974300,31.0806023600],
        [121.6738327504,31.0805916810],
        [121.6738237620,31.0806616586],
        [121.6736140436,31.0821672091],
        [121.6752877918,31.0822559592],
        [121.6779316490,31.0827596372],
        [121.6793359429,31.0829248808],
        [121.6859993210,31.0840248158],
        [121.6865812453,31.0840967081],
        [121.6867249803,31.0841144351],
        [121.6868507511,31.0841012127],
        [121.6875754347,31.0840249344],
        [121.6887423145,31.0841067742],
        [121.6964977999,31.0850147109],
        [121.6982623697,31.0795738422],
        [121.6992210513,31.0769567521],
        [121.6994746596,31.0768224153],
        [121.6996024877,31.0764564152],
        [121.7009816871,31.0721686323],
        [121.7001599763,31.0720710053],
        [121.7001960237,31.0708286546],
        [121.7002070365,31.0704698412],
        [121.7003030201,31.0688705985],
        [121.6968256508,31.0683805938],
        [121.6864099987,31.0668618050],
        [121.6821988928,31.0662599552],
        [121.6821657896,31.0682468825],
        [121.6809450986,31.0682661636],
        [121.6807364945,31.0682695545],
        [121.6807275719,31.0675449849],
        [121.6795118922,31.0675013048],
        [121.6794788814,31.0683878612],
        [121.6794578774,31.0689165992],
        [121.6783350541,31.0686009072],
        [121.6782890454,31.0697653304],
        [121.6782730409,31.0701881198],
        [121.6791593300,31.0703363549],
        [121.6780550691,31.0748728685],
        [121.6788864574,31.0750761756],
        [121.6787446928,31.0755101979],
        [121.6788105623,31.0755620436],
        [121.6778930594,31.0787259911],
        [121.6773910319,31.0786449915],
        [121.6775028609,31.0781130801],
        [121.6772693146,31.0780645519],
        [121.6766982317,31.0802683918],
        [121.6760365124,31.0802316741],
        [121.6756612313,31.0803103457],
        [121.6749386004,31.0806645255],
        [121.6739974300,31.0806023600],
        [121.6738327504,31.0805916810],
        [121.6738237620,31.0806616586],
        [121.6736140436,31.0821672091],
        [121.6752877918,31.0822559592],
        [121.6779316490,31.0827596372],
        [121.6793359429,31.0829248808],
        [121.6859993210,31.0840248158],
        [121.6865812453,31.0840967081],
        [121.6867249803,31.0841144351],
        [121.6868507511,31.0841012127],
        [121.6875754347,31.0840249344],
        [121.6887423145,31.0841067742],
        [121.6964977999,31.0850147109],
        [121.6966096138,31.0850285151],
        [121.6980123011,31.0852250645],
        [121.6974311539,31.0863364036],
        [121.6983027236,31.0865108607],
        [121.6982487956,31.0866898490],
        [121.6986611230,31.0867911132],
        [121.6985312972,31.0872030948],
        [121.6987938690,31.0872816195],
        [121.6981398125,31.0885919595],
        [121.6981108450,31.0887639108],
        [121.7009283542,31.0890621656],
        [121.7013766577,31.0891104253],
        [121.7019158275,31.0891625439],
        [121.7021085325,31.0891812304],
        [121.7023652515,31.0878905532],
        [121.7030442281,31.0879164814],
        [121.7028823862,31.0889211679],
        [121.7031609634,31.0889946948],
        [121.7031369712,31.0893285445],
        [121.7037490521,31.0894565298],
        [121.7050760949,31.0897333599],
        [121.7051220469,31.0895184115],
        [121.7053328285,31.0885176576],
        [121.7054656380,31.0885254547],
        [121.7055835247,31.0878716462],
        [121.7059150521,31.0878951403],
        [121.7059799600,31.0879000413],
        [121.7062686854,31.0863245010],
        [121.7065983747,31.0845290278],
        [121.7094065406,31.0849547714],
        [121.7103106885,31.0811526673],
        [121.7094408232,31.0810529257],
        [121.7093499488,31.0809750964],
        [121.7097815552,31.0789556390],
        [121.7099863701,31.0780018962],
        [121.7107503795,31.0781597611],
        [121.7115236249,31.0755931743],
        [121.7126602131,31.0758475275],
        [121.7135374380,31.0726462117],
        [121.7122699802,31.0723390332],
        [121.7124478365,31.0714782893],
        [121.7125598539,31.0696561807],
        [121.7113992887,31.0695267921],
        [121.7087147468,31.0695474766],
        [121.7042519929,31.0694240982],
        [121.7003030201,31.0688705985],
        [121.6968256508,31.0683805938],
        [121.6864099987,31.0668618050],
        [121.6821988928,31.0662599552],
        [121.6821657896,31.0682468825],
        [121.6809450986,31.0682661636],
        [121.6807364945,31.0682695545],
        [121.6807275719,31.0675449849],
        [121.6795118922,31.0675013048],
        [121.6794788814,31.0683878612],
        [121.6794578774,31.0689165992],
        [121.6783350541,31.0686009072],
        [121.6782890454,31.0697653304],
        [121.6782730409,31.0701881198],
        [121.6791593300,31.0703363549],
        [121.6780550691,31.0748728685],
        [121.6788864574,31.0750761756],
        [121.6787446928,31.0755101979],
        [121.6788105623,31.0755620436],
        [121.6778930594,31.0787259911],
        [121.6773910319,31.0786449915],
        [121.6775028609,31.0781130801],
        [121.6772693146,31.0780645519],
        [121.6766982317,31.0802683918],
        [121.6760365124,31.0802316741],
        [121.6756612313,31.0803103457],
        [121.6749386004,31.0806645255],
        [121.6739974300,31.0806023600],
        [121.6738327504,31.0805916810],
        [121.6738237620,31.0806616586],
        [121.6736140436,31.0821672091],
        [121.6752877918,31.0822559592],
        [121.6779316490,31.0827596372],
        [121.6793359429,31.0829248808],
        [121.6859993210,31.0840248158],
        [121.6865812453,31.0840967081],
        [121.6867249803,31.0841144351],
        [121.6868507511,31.0841012127],
        [121.6875754347,31.0840249344],
        [121.6887423145,31.0841067742],
        [121.6964977999,31.0850147109],
        [121.6966096138,31.0850285151],
        [121.6980123011,31.0852250645],
        [121.6974311539,31.0863364036],
        [121.6983027236,31.0865108607],
        [121.6982487956,31.0866898490],
        [121.6986611230,31.0867911132],
        [121.6985312972,31.0872030948],
        [121.6987938690,31.0872816195],
        [121.6981398125,31.0885919595],
        [121.6981108450,31.0887639108],
        [121.7009283542,31.0890621656],
        [121.7013766577,31.0891104253],
        [121.7019158275,31.0891625439],
        [121.7021085325,31.0891812304],
        [121.7023652515,31.0878905532],
        [121.7030442281,31.0879164814],
        [121.7028823862,31.0889211679],
        [121.7031609634,31.0889946948],
        [121.7031369712,31.0893285445],
        [121.7037490521,31.0894565298],
        [121.7050760949,31.0897333599],
        [121.7051220469,31.0895184115],
        [121.7053328285,31.0885176576],
        [121.7054656380,31.0885254547],
        [121.7055835247,31.0878716462],
        [121.7059150521,31.0878951403],
        [121.7059799600,31.0879000413],
        [121.7063054996,31.0879235474],
        [121.7060956951,31.0891101902],
        [121.7077192049,31.0919772152],
        [121.7077871025,31.0920970506],
        [121.7076981864,31.0925389309],
        [121.7076043094,31.0925990323],
        [121.7075174234,31.0926551262],
        [121.7074414952,31.0930370221],
        [121.7075283764,31.0930368967],
        [121.7087756948,31.0930331193],
        [121.7094817731,31.0929241908],
        [121.7098802531,31.0929426278],
        [121.7113124133,31.0931565566],
        [121.7124719723,31.0933289210],
        [121.7125438845,31.0933388213],
        [121.7134558006,31.0932846693],
        [121.7144027049,31.0932274985],
        [121.7146494245,31.0932121980],
        [121.7151268775,31.0933045531],
        [121.7155843608,31.0933929414],
        [121.7155923519,31.0933939311],
        [121.7155963474,31.0933949257],
        [121.7167670590,31.0936213889],
        [121.7173604246,31.0937356260],
        [121.7213825119,31.0939240676],
        [121.7226014456,31.0939817867],
        [121.7230661830,31.0924721681],
        [121.7227824236,31.0924284746],
        [121.7228653799,31.0921205643],
        [121.7245040491,31.0922499037],
        [121.7249118352,31.0910821766],
        [121.7256392677,31.0914033300],
        [121.7259890849,31.0905754798],
        [121.7265686606,31.0907758559],
        [121.7269575204,31.0892823571],
        [121.7270224976,31.0890324417],
        [121.7269905195,31.0890284713],
        [121.7274323825,31.0871621443],
        [121.7262182219,31.0870142766],
        [121.7264701602,31.0856498228],
        [121.7264991530,31.0854968835],
        [121.7265691040,31.0855028191],
        [121.7276213914,31.0855898704],
        [121.7276803865,31.0851940441],
        [121.7278083048,31.0851859421],
        [121.7278123051,31.0851519579],
        [121.7278283081,31.0849940336],
        [121.7280571610,31.0850128340],
        [121.7291235033,31.0851049253],
        [121.7301861774,31.0818339561],
        [121.7301212193,31.0817570483],
        [121.7287170474,31.0815182711],
        [121.7268472592,31.0813908975],
        [121.7267523276,31.0813470044],
        [121.7267493375,31.0812540595],
        [121.7273362059,31.0781283256],
        [121.7273442042,31.0780853432],
        [121.7285224539,31.0780933651],
        [121.7299935875,31.0782141487],
        [121.7307801608,31.0782795289],
        [121.7311359777,31.0782912658],
        [121.7312049480,31.0782322500],
        [121.7314659430,31.0767539033],
        [121.7314929425,31.0766029700],
        [121.7317849644,31.0746648667],
        [121.7263402077,31.0742763714],
        [121.7264262474,31.0730899702],
        [121.7265613105,31.0712239140],
        [121.7276365931,31.0711880153],
        [121.7277075921,31.0706542600],
        [121.7279914108,31.0706520260],
        [121.7280134530,31.0699843883],
        [121.7273998504,31.0699669100],
        [121.7273968524,31.0699669125],
        [121.7268102478,31.0699374307],
        [121.7268102404,31.0700253805],
        [121.7254571880,31.0701595050],
        [121.7226004148,31.0705760037],
        [121.7210507443,31.0709254003],
        [121.7175760906,31.0712460710],
        [121.7166960030,31.0713870256],
        [121.7162394962,31.0713515918],
        [121.7156311820,31.0711004719],
        [121.7146343554,31.0704300852],
        [121.7135236867,31.0698758070],
        [121.7125598539,31.0696561807],
        [121.7113992887,31.0695267921],
        [121.7087147468,31.0695474766],
        [121.7042519929,31.0694240982],
        [121.7003030201,31.0688705985],
        [121.6968256508,31.0683805938],
        [121.6968168351,31.0663507677],
        [121.6979010456,31.0665438497],
        [121.7039606448,31.0667400745],
        [121.7040765787,31.0654986070],
        [121.7015623693,31.0652786407],
        [121.7004571030,31.0650025604],
        [121.7005552627,31.0612665425],
        [121.7052911970,31.0609793589],
        [121.7050876268,31.0593366069],
        [121.7038993179,31.0597831522],
        [121.7034190231,31.0598368589],
        [121.7028958121,31.0597337274],
        [121.7024205619,31.0593197056],
        [121.7019053618,31.0591056369],
        [121.7020392319,31.0582219339],
        [121.7019344102,31.0579982273],
        [121.7020363267,31.0571425588],
        [121.7024818606,31.0546462970],
        [121.6993157616,31.0547273037],
        [121.6975995112,31.0548890355],
        [121.6966989873,31.0549005338],
        [121.6969087336,31.0538018148],
        [121.6971266136,31.0509550917],
        [121.6935067255,31.0505784774],
        [121.6920492648,31.0503841351],
        [121.6909740544,31.0515453636],
        [121.6889856225,31.0512310998],
        [121.6868974604,31.0506242387],
        [121.6873467401,31.0494251115],
        [121.6873407663,31.0492412286],
        [121.6808230313,31.0478541211],
        [121.6801820375,31.0504118518],
        [121.6807369788,31.0504467853],
        [121.6801268806,31.0535561424],
        [121.6814094339,31.0536786562],
        [121.6808862268,31.0560702633],
        [121.6805397963,31.0571363028],
        [121.6799120077,31.0569475960],
        [121.6799619438,31.0565717178],
        [121.6795687016,31.0564835118],
        [121.6796445930,31.0560476190],
        [121.6796685586,31.0559106525],
        [121.6797114971,31.0556657123],
        [121.6797075047,31.0556657199],
        [121.6791505779,31.0555588351],
        [121.6788690096,31.0568456283],
        [121.6787322714,31.0568478862],
        [121.6778230148,31.0568666015],
        [121.6776332119,31.0588828036],
        [121.6764225556,31.0587501848],
        [121.6759474773,31.0586991202],
        [121.6759155236,31.0588850743],
        [121.6755460583,31.0610425413],
        [121.6743523827,31.0608859119],
        [121.6729680837,31.0607036647],
        [121.6729261459,31.0609346125],
        [121.6726076168,31.0627112039],
        [121.6726046215,31.0627242021],
        [121.6726595142,31.0627320925],
        [121.6732623359,31.0628258851],
        [121.6732893045,31.0625739778],
        [121.6739290577,31.0626387167],
        [121.6736993801,31.0641183088],
        [121.6807667384,31.0646545631],
        [121.6807356811,31.0660498238],
        [121.6807346797,31.0660898028],
        [121.6821988928,31.0662599552],
        [121.6864099987,31.0668618050],
        [121.6968256508,31.0683805938],
        [121.7003030201,31.0688705985],
        [121.7042519929,31.0694240982],
        [121.7087147468,31.0695474766],
        [121.7113992887,31.0695267921],
        [121.7125598539,31.0696561807],
        [121.7135236867,31.0698758070],
        [121.7146343554,31.0704300852],
        [121.7156311820,31.0711004719],
        [121.7162394962,31.0713515918],
        [121.7166960030,31.0713870256],
        [121.7175760906,31.0712460710],
        [121.7210507443,31.0709254003],
        [121.7226004148,31.0705760037],
        [121.7254571880,31.0701595050],
        [121.7268102404,31.0700253805],
        [121.7268102478,31.0699374307],
        [121.7273968524,31.0699669125],
        [121.7280134530,31.0699843883],
        [121.7280164577,31.0699054308],
        [121.7286190844,31.0698979452],
        [121.7298663589,31.0698829797],
        [121.7298924293,31.0688735356],
        [121.7297595073,31.0688296617],
        [121.7266455407,31.0677957953],
        [121.7266885351,31.0675109205],
        [121.7253075815,31.0667935622],
        [121.7256224751,31.0653051264],
        [121.7239388205,31.0645681187],
        [121.7241297328,31.0638133678],
        [121.7239968401,31.0637865103],
        [121.7241467913,31.0629568420],
        [121.7242956724,31.0629826855],
        [121.7244296183,31.0623849012],
        [121.7231926260,31.0621742156],
        [121.7206458859,31.0617420777],
        [121.7204969616,31.0624798142],
        [121.7197546690,31.0623726801],
        [121.7195627876,31.0631494459],
        [121.7175977513,31.0631136904],
        [121.7181235480,31.0591233721],
        [121.7152266402,31.0590828415],
        [121.7148558601,31.0614259560],
        [121.7147837497,31.0637137350],
        [121.7145530002,31.0638529435],
        [121.7135671257,31.0640380860],
        [121.7120808454,31.0647805954],
        [121.7108932886,31.0651729604],
        [121.7102640855,31.0652167930],
        [121.7097907197,31.0649356060],
        [121.7097048424,31.0648018015],
        [121.7098527684,31.0633954017],
        [121.7111041646,31.0634876398],
        [121.7112901122,31.0613356225],
        [121.7115957326,31.0613272179],
        [121.7116088274,31.0600039595],
        [121.7120882375,31.0600003247],
        [121.7120743375,31.0590109111],
        [121.7109038122,31.0587736140],
        [121.7103075940,31.0584895894],
        [121.7098532062,31.0581683993],
        [121.7100582536,31.0544442588],
        [121.7072829134,31.0548769316],
        [121.7064540566,31.0549471005],
        [121.7054854282,31.0549075577],
        [121.7036910549,31.0546494228],
        [121.7024818606,31.0546462970],
        [121.6993157616,31.0547273037],
        [121.6975995112,31.0548890355],
        [121.6966989873,31.0549005338],
        [121.6969087336,31.0538018148],
        [121.6971266136,31.0509550917],
        [121.6935067255,31.0505784774],
        [121.6938827225,31.0429052654],
        [121.6990761286,31.0429485008],
        [121.7021074343,31.0426208329],
        [121.7037569757,31.0424913481],
        [121.7045528161,31.0424281718],
        [121.7045907659,31.0423691486],
        [121.7048674806,31.0409925292],
        [121.7038210322,31.0406882960],
        [121.7039938401,31.0399484612],
        [121.7035924342,31.0399180940],
        [121.7034485896,31.0406069154],
        [121.7026637797,31.0403932552],
        [121.7024501067,31.0403346226],
        [121.7025899299,31.0399276407],
        [121.7026638362,31.0397176474],
        [121.7028476051,31.0391816728],
        [121.7030912380,31.0392122771],
        [121.7031931571,31.0383626131],
        [121.7035636039,31.0384000202],
        [121.7036035709,31.0380871406],
        [121.7034537954,31.0380553894],
        [121.7035197485,31.0374476412],
        [121.7032521477,31.0374250669],
        [121.7032711489,31.0370732421],
        [121.7036645630,31.0371056175],
        [121.7036236449,31.0368488297],
        [121.7037565177,31.0360251049],
        [121.7039292866,31.0357470022],
        [121.7039962155,31.0354210896],
        [121.7040042632,31.0347104909],
        [121.7047272036,31.0347793526],
        [121.7046873093,31.0342057469],
        [121.7046703539,31.0339669116],
        [121.7057547842,31.0341421838],
        [121.7070419650,31.0343511693],
        [121.7068074744,31.0321447966],
        [121.7071490037,31.0321263100],
        [121.7070761828,31.0311859641],
        [121.7065658887,31.0312106948],
        [121.7064600942,31.0305212522],
        [121.7058319744,31.0305641547],
        [121.7035243046,31.0307225508],
        [121.7034244437,31.0308286427],
        [121.7029211940,31.0308264223],
        [121.7029301915,31.0306954847],
        [121.7030031653,31.0297039500],
        [121.7018928557,31.0295347839],
        [121.7020736566,31.0286260299],
        [121.7008265729,31.0286140130],
        [121.6995915094,31.0286030087],
        [121.6961890321,31.0285756619],
        [121.6931162374,31.0285499485],
        [121.6932810155,31.0278070956],
        [121.6903681260,31.0275913513],
        [121.6902842837,31.0274855626],
        [121.6904360433,31.0271384948],
        [121.6888278909,31.0274441989],
        [121.6875132565,31.0276084845],
        [121.6868135369,31.0275657855],
        [121.6857095843,31.0273379433],
        [121.6855498715,31.0274301834],
        [121.6852941247,31.0300641170],
        [121.6788822471,31.0296633563],
        [121.6783380664,31.0323498216],
        [121.6787582595,31.0323670145],
        [121.6786494326,31.0327919732],
        [121.6790506600,31.0328451817],
        [121.6788519745,31.0336350980],
        [121.6795226919,31.0336348281],
        [121.6789115688,31.0371399452],
        [121.6795373652,31.0372237117],
        [121.6793436313,31.0384733518],
        [121.6811192093,31.0389687135],
        [121.6810233320,31.0396794810],
        [121.6804633971,31.0396625456],
        [121.6792425884,31.0413088965],
        [121.6781417047,31.0412250326],
        [121.6760587294,31.0410680887],
        [121.6695114467,31.0408847180],
        [121.6665212777,31.0404826573],
        [121.6664873438,31.0404777248],
        [121.6656320068,31.0403534230],
        [121.6655880672,31.0406473358],
        [121.6653933345,31.0419539473],
        [121.6656957393,31.0420942917],
        [121.6654970765,31.0426523454],
        [121.6653613337,31.0427085704],
        [121.6651477482,31.0426769939],
        [121.6648222600,31.0440678048],
        [121.6646545852,31.0440401385],
        [121.6644469190,31.0448340717],
        [121.6637832021,31.0447423797],
        [121.6636673842,31.0452273175],
        [121.6624277788,31.0449708010],
        [121.6615255142,31.0447846012],
        [121.6613857538,31.0451016792],
        [121.6612879218,31.0453177371],
        [121.6665058274,31.0462693328],
        [121.6703263585,31.0469656357],
        [121.6702634569,31.0472525901],
        [121.6702185273,31.0474555587],
        [121.6709061843,31.0475371955],
        [121.6709321477,31.0473702424],
        [121.6709760856,31.0470903202],
        [121.6731967403,31.0474688504],
        [121.6739273193,31.0475114280],
        [121.6766849714,31.0476710698],
        [121.6767937845,31.0473960216],
        [121.6788168872,31.0475061130],
        [121.6788498516,31.0471752419],
        [121.6788578434,31.0470892765],
        [121.6810187125,31.0472221182],
        [121.6808230313,31.0478541211],
        [121.6873407663,31.0492412286],
        [121.6873467401,31.0494251115],
        [121.6868974604,31.0506242387],
        [121.6889856225,31.0512310998],
        [121.6909740544,31.0515453636],
        [121.6920492648,31.0503841351],
        [121.6935067255,31.0505784774],
        [121.6971266136,31.0509550917],
        [121.6969087336,31.0538018148],
        [121.6966989873,31.0549005338],
        [121.6975995112,31.0548890355],
        [121.6993157616,31.0547273037],
        [121.7024818606,31.0546462970],
        [121.7036910549,31.0546494228],
        [121.7054854282,31.0549075577],
        [121.7064540566,31.0549471005],
        [121.7072829134,31.0548769316],
        [121.7100582536,31.0544442588],
        [121.7098532062,31.0581683993],
        [121.7103075940,31.0584895894],
        [121.7109038122,31.0587736140],
        [121.7120743375,31.0590109111],
        [121.7120882375,31.0600003247],
        [121.7116088274,31.0600039595],
        [121.7115957326,31.0613272179],
        [121.7112901122,31.0613356225],
        [121.7111041646,31.0634876398],
        [121.7098527684,31.0633954017],
        [121.7097048424,31.0648018015],
        [121.7097907197,31.0649356060],
        [121.7102640855,31.0652167930],
        [121.7108932886,31.0651729604],
        [121.7120808454,31.0647805954],
        [121.7135671257,31.0640380860],
        [121.7145530002,31.0638529435],
        [121.7147837497,31.0637137350],
        [121.7148558601,31.0614259560],
        [121.7152266402,31.0590828415],
        [121.7181235480,31.0591233721],
        [121.7175977513,31.0631136904],
        [121.7195627876,31.0631494459],
        [121.7197546690,31.0623726801],
        [121.7204969616,31.0624798142],
        [121.7206458859,31.0617420777],
        [121.7231926260,31.0621742156],
        [121.7244296183,31.0623849012],
        [121.7244646046,31.0622259592],
        [121.7242827485,31.0621971478],
        [121.7248395410,31.0595591375],
        [121.7255050401,31.0596054987],
        [121.7255420512,31.0591507262],
        [121.7216801804,31.0590365431],
        [121.7215234524,31.0574496174],
        [121.7205952992,31.0573776373],
        [121.7193604952,31.0570501695],
        [121.7193565733,31.0561656826],
        [121.7197841633,31.0561522195],
        [121.7198082387,31.0549818667],
        [121.7194445866,31.0549932600],
        [121.7192117589,31.0556311509],
        [121.7185273541,31.0565893682],
        [121.7183375384,31.0566625418],
        [121.7181767064,31.0565957639],
        [121.7181507336,31.0565847999],
        [121.7181468348,31.0554264708],
        [121.7181439213,31.0544320467],
        [121.7181439222,31.0544210531],
        [121.7181848803,31.0544260033],
        [121.7185594999,31.0544705512],
        [121.7194736079,31.0544055663],
        [121.7208413478,31.0541082566],
        [121.7227657338,31.0532197858],
        [121.7251349546,31.0519862218],
        [121.7256156206,31.0517359273],
        [121.7258354763,31.0515568325],
        [121.7260253531,31.0514027515],
        [121.7269767546,31.0506703417],
        [121.7289105870,31.0499381675],
        [121.7304507408,31.0496631472],
        [121.7304277826,31.0493113673],
        [121.7298281096,31.0493168139],
        [121.7298291926,31.0483213883],
        [121.7285619314,31.0483433673],
        [121.7285679648,31.0479016178],
        [121.7277694678,31.0478692897],
        [121.7276665390,31.0478114090],
        [121.7276637563,31.0452468952],
        [121.7271571017,31.0450984089],
        [121.7267453783,31.0451177517],
        [121.7267213163,31.0460512321],
        [121.7258089580,31.0460510379],
        [121.7258489975,31.0452374730],
        [121.7254882636,31.0451918252],
        [121.7248777255,31.0451164302],
        [121.7248867644,31.0445717373],
        [121.7243271954,31.0445642669],
        [121.7243401409,31.0450929484],
        [121.7236346960,31.0451465940],
        [121.7234338576,31.0451617809],
        [121.7234387697,31.0461601981],
        [121.7229621620,31.0461646650],
        [121.7229541369,31.0465424544],
        [121.7224006042,31.0465420093],
        [121.7224496756,31.0451927408],
        [121.7224117079,31.0451937785],
        [121.7217982385,31.0452023997],
        [121.7218063026,31.0443538828],
        [121.7214656123,31.0442522946],
        [121.7210559729,31.0443386737],
        [121.7205873847,31.0445390552],
        [121.7191756748,31.0451422466],
        [121.7184833552,31.0451889978],
        [121.7179119362,31.0451536718],
        [121.7175393216,31.0451291175],
        [121.7166972262,31.0448902465],
        [121.7166342867,31.0449692756],
        [121.7166272736,31.0452151416],
        [121.7165962288,31.0461436411],
        [121.7153745680,31.0460621667],
        [121.7150509315,31.0460405783],
        [121.7150449261,31.0461855018],
        [121.7150189088,31.0467392136],
        [121.7144885132,31.0467008969],
        [121.7144845000,31.0469137788],
        [121.7144794866,31.0471416532],
        [121.7132419405,31.0469803208],
        [121.7132419419,31.0469633306],
        [121.7132369987,31.0463566880],
        [121.7114052411,31.0461642088],
        [121.7113942388,31.0463551131],
        [121.7113652324,31.0468618588],
        [121.7102676245,31.0468113771],
        [121.7102646210,31.0468993303],
        [121.7101587571,31.0468964773],
        [121.7101387828,31.0468965047],
        [121.7097133337,31.0468850987],
        [121.7097133343,31.0468781027],
        [121.7097094389,31.0456907952],
        [121.7097094649,31.0453799751],
        [121.7083123262,31.0451980427],
        [121.7075203888,31.0453211060],
        [121.7071069509,31.0453866666],
        [121.7061722498,31.0454230145],
        [121.7060963564,31.0454261248],
        [121.7060424322,31.0454282034],
        [121.7060334450,31.0454272172],
        [121.7047592786,31.0452722116],
        [121.7050740563,31.0425043413],
        [121.7050930436,31.0423304136],
        [121.7045907659,31.0423691486],
        [121.7048674806,31.0409925292],
        [121.7038210322,31.0406882960],
        [121.7039938401,31.0399484612],
        [121.7035924342,31.0399180940],
        [121.7034485896,31.0406069154],
        [121.7026637797,31.0403932552],
        [121.7024501067,31.0403346226],
        [121.7025899299,31.0399276407],
        [121.7026638362,31.0397176474],
        [121.7028476051,31.0391816728],
        [121.7030912380,31.0392122771],
        [121.7031931571,31.0383626131],
        [121.7035636039,31.0384000202],
        [121.7036035709,31.0380871406],
        [121.7034537954,31.0380553894],
        [121.7035197485,31.0374476412],
        [121.7032521477,31.0374250669],
        [121.7032711489,31.0370732421],
        [121.7036645630,31.0371056175],
        [121.7036236449,31.0368488297],
        [121.7037565177,31.0360251049],
        [121.7039292866,31.0357470022],
        [121.7039962155,31.0354210896],
        [121.7040042632,31.0347104909],
        [121.7047272036,31.0347793526],
        [121.7046873093,31.0342057469],
        [121.7046703539,31.0339669116],
        [121.7057547842,31.0341421838],
        [121.7070419650,31.0343511693],
        [121.7068074744,31.0321447966],
        [121.7071490037,31.0321263100],
        [121.7070761828,31.0311859641],
        [121.7065658887,31.0312106948],
        [121.7064600942,31.0305212522],
        [121.7058319744,31.0305641547],
        [121.7035243046,31.0307225508],
        [121.7034244437,31.0308286427],
        [121.7029211940,31.0308264223],
        [121.7029301915,31.0306954847],
        [121.7030031653,31.0297039500],
        [121.7018928557,31.0295347839],
        [121.7020736566,31.0286260299],
        [121.7008265729,31.0286140130],
        [121.6995915094,31.0286030087],
        [121.6961890321,31.0285756619],
        [121.6931162374,31.0285499485],
        [121.6932810155,31.0278070956],
        [121.6903681260,31.0275913513],
        [121.6902842837,31.0274855626],
        [121.6904360433,31.0271384948],
        [121.6888278909,31.0274441989],
        [121.6875132565,31.0276084845],
        [121.6868135369,31.0275657855],
        [121.6857095843,31.0273379433],
        [121.6855498715,31.0274301834],
        [121.6852941247,31.0300641170],
        [121.6788822471,31.0296633563],
        [121.6852941247,31.0300641170],
        [121.6855498715,31.0274301834],
        [121.6857095843,31.0273379433],
        [121.6866575133,31.0192779207],
        [121.6886319204,31.0193313001],
        [121.6890551709,31.0191876212],
        [121.6895952054,31.0191636649],
        [121.6897241331,31.0172685451],
        [121.6900435678,31.0172200021],
        [121.6900755660,31.0165593325],
        [121.6909569956,31.0166657009],
        [121.6910530858,31.0135533577],
        [121.6709977808,31.0141245033],
        [121.6656022183,31.0143746533],
        [121.6621378537,31.0145361017],
        [121.6622475402,31.0157951566],
        [121.6631827515,31.0158073900],
        [121.6646609119,31.0158265860],
        [121.6656370298,31.0158377282],
        [121.6656669719,31.0158396702],
        [121.6732402750,31.0159461413],
        [121.6733340929,31.0159469613],
        [121.6745317696,31.0159646601],
        [121.6745476479,31.0170599870],
        [121.6741713752,31.0170806943],
        [121.6741793135,31.0176383520],
        [121.6730385196,31.0177154893],
        [121.6730405028,31.0178713941],
        [121.6722769834,31.0178918436],
        [121.6722959167,31.0182525958],
        [121.6718737355,31.0182653965],
        [121.6719086368,31.0186381111],
        [121.6712748624,31.0187042856],
        [121.6716918588,31.0210361208],
        [121.6704223356,31.0209096249],
        [121.6666496842,31.0205310506],
        [121.6666895965,31.0206579004],
        [121.6673132245,31.0226465478],
        [121.6684769734,31.0225883613],
        [121.6683950748,31.0232821116],
        [121.6673930250,31.0231880787],
        [121.6672651328,31.0248753354],
        [121.6660385200,31.0246997731],
        [121.6659387139,31.0246859708],
        [121.6639954800,31.0244098156],
        [121.6639605304,31.0246127630],
        [121.6637458404,31.0258454478],
        [121.6639803869,31.0258829827],
        [121.6636129659,31.0274067862],
        [121.6642657018,31.0275344782],
        [121.6642117658,31.0280133006],
        [121.6641788050,31.0283051925],
        [121.6643155480,31.0282339754],
        [121.6647437430,31.0280072972],
        [121.6655142605,31.0279758543],
        [121.6655321713,31.0286354351],
        [121.6656249750,31.0288431377],
        [121.6662347902,31.0289279293],
        [121.6670781484,31.0290522512],
        [121.6683057542,31.0292338037],
        [121.6685254206,31.0281190350],
        [121.6685893235,31.0277941027],
        [121.6700674213,31.0281830498],
        [121.6705574761,31.0281011599],
        [121.6713828644,31.0281975238],
        [121.6733669509,31.0289282993],
        [121.6739138699,31.0291701118],
        [121.6740905159,31.0293096924],
        [121.6740935101,31.0293096867],
        [121.6744308344,31.0295738875],
        [121.6751494210,31.0298383599],
        [121.6752672173,31.0295473048],
        [121.6752861845,31.0295012954],
        [121.6753111412,31.0294402833],
        [121.6753810206,31.0292662515],
        [121.6765766902,31.0295188230],
        [121.6767952649,31.0295653794],
        [121.6788822471,31.0296633563],
        [121.6852941247,31.0300641170],
        [121.6855498715,31.0274301834],
        [121.6857095843,31.0273379433],
        [121.6868135351,31.0275657860],
        [121.6875132563,31.0276084866],
        [121.6888278897,31.0274442022],
        [121.6904360417,31.0271384957],
        [121.6902842821,31.0274855651],
        [121.6903681221,31.0275913516],
        [121.6932810115,31.0278070991],
        [121.6931162374,31.0285499485],
        [121.6961890321,31.0285756619],
        [121.6995915094,31.0286030087],
        [121.7008265729,31.0286140130],
        [121.7020736566,31.0286260299],
        [121.7025549640,31.0281835383],
        [121.7037491756,31.0282526481],
        [121.7038381129,31.0274659745],
        [121.7027527507,31.0271438378],
        [121.7029506475,31.0248418748],
        [121.7042158014,31.0245700899],
        [121.7054490780,31.0238476505],
        [121.7070968030,31.0233774973],
        [121.7077768844,31.0232865704],
        [121.7078189249,31.0220982042],
        [121.7078239279,31.0219582787],
        [121.7095995607,31.0220557166],
        [121.7140160903,31.0224366230],
        [121.7142757878,31.0224592808],
        [121.7158640118,31.0224023466],
        [121.7167670361,31.0223702834],
        [121.7182395217,31.0222416447],
        [121.7215354170,31.0220031862],
        [121.7231690290,31.0218566162],
        [121.7239064584,31.0215081032],
        [121.7238944827,31.0213661970],
        [121.7224868573,31.0187970923],
        [121.7241455417,31.0182527825],
        [121.7236610886,31.0163813482],
        [121.7243505787,31.0159429452],
        [121.7247233010,31.0158356552],
        [121.7250460619,31.0157424088],
        [121.7257735430,31.0155308723],
        [121.7259493901,31.0158275378],
        [121.7263370636,31.0164768118],
        [121.7275362625,31.0163358634],
        [121.7271436699,31.0146012166],
        [121.7256816669,31.0148323619],
        [121.7253748892,31.0148816143],
        [121.7253469303,31.0146297849],
        [121.7252231139,31.0135565324],
        [121.7239490908,31.0135977038],
        [121.7236124317,31.0127485275],
        [121.7223684629,31.0127947324],
        [121.7214612529,31.0128286441],
        [121.7168876936,31.0129996346],
        [121.7163262947,31.0130302866],
        [121.7163741763,31.0137997757],
        [121.7160125653,31.0138231989],
        [121.7161961133,31.0168761840],
        [121.7138477293,31.0171149516],
        [121.7105647316,31.0170962927],
        [121.7092434294,31.0172200447],
        [121.7057781620,31.0172610215],
        [121.7029982252,31.0171123297],
        [121.7028867302,31.0130598790],
        [121.7028727953,31.0125102249],
        [121.7024134827,31.0125479171],
        [121.7023599171,31.0083574686],
        [121.6959660652,31.0086127450],
        [121.6959127563,31.0133900282],
        [121.6910530807,31.0135533581],
        [121.6959127601,31.0133900240],
        [121.6959660701,31.0086127424],
        [121.6959915095,31.0028410985],
        [121.6945149835,31.0029295644],
        [121.6756425449,31.0048360821],
        [121.6726842611,31.0050206297],
        [121.6702290046,31.0053201529],
        [121.6689744250,31.0055064424],
        [121.6688386898,31.0054897116],
        [121.6682418535,31.0054168944],
        [121.6682418539,31.0054118974],
        [121.6681301647,31.0042757798],
        [121.6668875715,31.0042751496],
        [121.6662917237,31.0042752836],
        [121.6662038567,31.0047191892],
        [121.6651549111,31.0043533969],
        [121.6649992134,31.0043257084],
        [121.6643474770,31.0042110103],
        [121.6643254984,31.0044629035],
        [121.6642176019,31.0057173687],
        [121.6640237895,31.0079474223],
        [121.6630666156,31.0080571638],
        [121.6631792722,31.0096000438],
        [121.6624306916,31.0097443677],
        [121.6623169635,31.0090829704],
        [121.6618787998,31.0090717993],
        [121.6593156571,31.0090126118],
        [121.6593126628,31.0090126173],
        [121.6593086703,31.0090126247],
        [121.6592108543,31.0090108069],
        [121.6594024273,31.0098179773],
        [121.6591389181,31.0098734326],
        [121.6591728181,31.0103131113],
        [121.6593405040,31.0102988092],
        [121.6596258916,31.0112077457],
        [121.6598794165,31.0111732954],
        [121.6599013714,31.0112182282],
        [121.6601708164,31.0117773985],
        [121.6601947672,31.0118253258],
        [121.6602885956,31.0117561918],
        [121.6602885958,31.0117531936],
        [121.6605281577,31.0115718540],
        [121.6607567392,31.0113975303],
        [121.6607906769,31.0113724817],
        [121.6608825087,31.0113013521],
        [121.6619954162,31.0110214340],
        [121.6620812211,31.0114020493],
        [121.6617797892,31.0114775701],
        [121.6618067169,31.0117313705],
        [121.6614384105,31.0118180094],
        [121.6615381996,31.0120736723],
        [121.6614942092,31.0129682290],
        [121.6605649698,31.0129999462],
        [121.6605489910,31.0131089119],
        [121.6599341494,31.0131540296],
        [121.6599122023,31.0130151520],
        [121.6590448392,31.0129627906],
        [121.6589460252,31.0129569767],
        [121.6589260525,31.0130799414],
        [121.6587024712,31.0130863506],
        [121.6586994729,31.0131343280],
        [121.6586204989,31.0146086078],
        [121.6586164998,31.0146885683],
        [121.6608113711,31.0145935506],
        [121.6621378537,31.0145361017],
        [121.6656022183,31.0143746533],
        [121.6709977808,31.0141245033],
        [121.6910530858,31.0135533577],
        [121.6959127601,31.0133900240],
        [121.6959660701,31.0086127424],
        [121.7023599176,31.0083574644],
        [121.7024134863,31.0125479164],
        [121.7028727985,31.0125102228],
        [121.7028867316,31.0130598782],
        [121.7029982259,31.0171123260],
        [121.7057781640,31.0172610176],
        [121.7092434330,31.0172200427],
        [121.7105647349,31.0170962905],
        [121.7138477328,31.0171149513],
        [121.7161961140,31.0168761831],
        [121.7160125692,31.0138231968],
        [121.7163741796,31.0137997752],
        [121.7163262957,31.0130302846],
        [121.7168876985,31.0129996336],
        [121.7168657604,31.0125379309],
        [121.7168587796,31.0123970219],
        [121.7167022210,31.0091141380],
        [121.7169319842,31.0090319140],
        [121.7170059149,31.0089278879],
        [121.7169640007,31.0084322290],
        [121.7174554949,31.0083287131],
        [121.7175254281,31.0082606717],
        [121.7174405527,31.0078230282],
        [121.7173916248,31.0075702341],
        [121.7178991208,31.0073397808],
        [121.7197493317,31.0068699772],
        [121.7202418864,31.0066285835],
        [121.7210431815,31.0062349600],
        [121.7211201141,31.0062088943],
        [121.7219484027,31.0059281990],
        [121.7223470630,31.0058718259],
        [121.7236969925,31.0052708422],
        [121.7231005794,31.0040741309],
        [121.7242227174,31.0035743371],
        [121.7240718600,31.0032886493],
        [121.7244755637,31.0030584016],
        [121.7243187152,31.0027057578],
        [121.7241188652,31.0027849013],
        [121.7240119638,31.0026151037],
        [121.7238420924,31.0026932209],
        [121.7236612558,31.0024735253],
        [121.7231346625,31.0027468795],
        [121.7229198835,31.0022383920],
        [121.7226261180,31.0023835999],
        [121.7223513405,31.0025187972],
        [121.7218447581,31.0027691668],
        [121.7212482623,31.0030646122],
        [121.7211064197,31.0027119690],
        [121.7208356669,31.0026972639],
        [121.7208216836,31.0026503064],
        [121.7201824563,31.0004942629],
        [121.7191084422,31.0010081399],
        [121.7186878398,31.0012104925],
        [121.7177856953,31.0018701342],
        [121.7164810145,31.0024663169],
        [121.7162672410,31.0025095476],
        [121.7161673545,31.0024437066],
        [121.7161693857,31.0020459387],
        [121.7161805032,31.0004988377],
        [121.7154775183,30.9975744173],
        [121.7147433113,30.9979281160],
        [121.7113052679,30.9995855919],
        [121.7089531754,31.0009740069],
        [121.7079534764,31.0014081693],
        [121.7059961354,31.0019636920],
        [121.7039859974,31.0023444914],
        [121.7022865161,31.0025250145],
        [121.6959915095,31.0028410985],
        [121.7022865161,31.0025250145],
        [121.7039859974,31.0023444914],
        [121.7059961354,31.0019636920],
        [121.7079534764,31.0014081693],
        [121.7089531754,31.0009740069],
        [121.7113052679,30.9995855919],
        [121.7111564931,30.9991170685],
        [121.7109048753,30.9983288735],
        [121.7120924486,30.9977576216],
        [121.7117888700,30.9971733691],
        [121.7118847545,30.9971442588],
        [121.7116770493,30.9966808088],
        [121.7118568340,30.9966046145],
        [121.7112576769,30.9954081218],
        [121.7122754439,30.9951479211],
        [121.7117401919,30.9940452812],
        [121.7114185791,30.9941796315],
        [121.7110191590,30.9932197362],
        [121.7126262330,30.9925939699],
        [121.7122587413,30.9918368996],
        [121.7118472324,30.9919853562],
        [121.7117653409,30.9918945187],
        [121.7115436097,30.9919577771],
        [121.7112090842,30.9912676338],
        [121.7106877285,30.9914142526],
        [121.7107406168,30.9919488646],
        [121.7107466044,30.9920058228],
        [121.7101483486,30.9922315070],
        [121.7099166221,30.9925316488],
        [121.7099964651,30.9931761578],
        [121.7100523549,30.9936318116],
        [121.7100533534,30.9936348084],
        [121.7091654786,30.9940018246],
        [121.7087900593,30.9929919489],
        [121.7087121801,30.9927841816],
        [121.7085853776,30.9924415634],
        [121.7082958304,30.9916604360],
        [121.7071613448,30.9919958684],
        [121.7059729720,30.9923494020],
        [121.7058611575,30.9920207620],
        [121.7037307613,30.9857316963],
        [121.7036848406,30.9855938483],
        [121.7035430408,30.9857050004],
        [121.7033333350,30.9859052050],
        [121.7032214909,30.9860293042],
        [121.7031336138,30.9861253833],
        [121.7029738331,30.9863554946],
        [121.7028829541,30.9865355291],
        [121.7028330197,30.9866455416],
        [121.7028140448,30.9866855474],
        [121.7027141654,30.9870354958],
        [121.7026242638,30.9874753755],
        [121.7025942922,30.9876753040],
        [121.7025842923,30.9878552132],
        [121.7022048653,30.9878558062],
        [121.7016856565,30.9878466287],
        [121.7015648414,30.9878468196],
        [121.7014849622,30.9878669341],
        [121.7014360348,30.9878969939],
        [121.7014070768,30.9879270220],
        [121.7010865713,30.9879175369],
        [121.6997187096,30.9878907504],
        [121.6995110386,30.9878840914],
        [121.6982700251,30.9878521424],
        [121.6981811685,30.9878522890],
        [121.6979215894,30.9878427240],
        [121.6976620119,30.9878331603],
        [121.6975202372,30.9879013557],
        [121.6974453564,30.9879364596],
        [121.6974223808,30.9880934050],
        [121.6972296968,30.9880817333],
        [121.6969002250,30.9882311960],
        [121.6953636753,30.9894610650],
        [121.6951570088,30.9896263197],
        [121.6948625014,30.9896717963],
        [121.6930795210,30.9899497115],
        [121.6929457473,30.9900039126],
        [121.6907335371,30.9909132704],
        [121.6904250750,30.9910097622],
        [121.6904210821,30.9910097693],
        [121.6891004038,30.9914198920],
        [121.6883667058,30.9916470816],
        [121.6880782200,30.9917375505],
        [121.6878216821,30.9917719956],
        [121.6860019870,30.9920211721],
        [121.6856645989,30.9921377236],
        [121.6853032560,30.9922623157],
        [121.6840755308,30.9923245507],
        [121.6835964262,30.9923154466],
        [121.6819595049,30.9922785261],
        [121.6818397310,30.9922787505],
        [121.6817998073,30.9922678319],
        [121.6814804143,30.9922294541],
        [121.6813306994,30.9922087476],
        [121.6807957195,30.9921387955],
        [121.6801529487,30.9920520586],
        [121.6795900241,30.9920231393],
        [121.6795860318,30.9920221474],
        [121.6794682571,30.9920163736],
        [121.6793634576,30.9920115747],
        [121.6792147425,30.9920028614],
        [121.6788554229,30.9920844938],
        [121.6787356503,30.9921067077],
        [121.6787326559,30.9921087122],
        [121.6772884050,30.9923872910],
        [121.6758012163,30.9930597278],
        [121.6757503141,30.9930668207],
        [121.6757341746,30.9951216376],
        [121.6757331654,30.9952565598],
        [121.6777403154,30.9950448613],
        [121.6780487258,30.9950122945],
        [121.6790488077,30.9950353838],
        [121.6807934671,30.9952259757],
        [121.6807973843,30.9961324330],
        [121.6813972408,30.9962092593],
        [121.6814590345,30.9972835089],
        [121.6823783049,30.9972128271],
        [121.6822843698,30.9985602076],
        [121.6824340821,30.9986288869],
        [121.6822373432,30.9999454783],
        [121.6830438276,30.9999329783],
        [121.6830188407,31.0003387855],
        [121.6826285691,31.0003964804],
        [121.6826684154,31.0013428477],
        [121.6798476982,31.0020617261],
        [121.6766617828,31.0023675929],
        [121.6766617955,31.0022136836],
        [121.6762665586,31.0022034430],
        [121.6762655518,31.0023083831],
        [121.6762655453,31.0023873365],
        [121.6741586174,31.0024193416],
        [121.6743142383,31.0033534934],
        [121.6735646858,31.0034188885],
        [121.6734030836,31.0024017972],
        [121.6734000915,31.0023768177],
        [121.6726355949,31.0021364225],
        [121.6719139969,31.0021248107],
        [121.6719349484,31.0022187153],
        [121.6721644204,31.0032126901],
        [121.6708140394,31.0032572488],
        [121.6708858640,31.0036898564],
        [121.6700504732,31.0038503604],
        [121.6702290046,31.0053201529],
        [121.6726842611,31.0050206297],
        [121.6756425449,31.0048360821],
        [121.6945149835,31.0029295644],
        [121.6959915095,31.0028410985],

        //新安村村委会

        //张家桥村村委会
        //张家桥村村委会
        //张家桥村村委会
        //张家桥村村委会
        // [116.39, 39.91, 116.37, 39.91],//起点
        // //第一段弧线
        // [116.380298, 39.907771, 116.38, 39.90],//控制点，途经点
        // //第二段弧线
        // [116.385298, 39.907771, 116.40, 39.90],//控制点，途经点//弧线段有两种描述方式1
        // //第三段弧线
        // [//弧线段有两种描述方式2
        //   [116.392872, 39.887391],//控制点
        //   [116.40772, 39.909252],//控制点
        //   [116.41, 39.89]//途经点
        // ],
        // //第四段弧线
        // [116.39, 39.91, 116.37, 39.91]
        // //控制点，控制点，途经点，每段最多两个控制点
      ];

      var bezierCurve = new AMap.BezierCurve({
        path: path,
        isOutline: true,
        // outlineColor: '#ffeeff',
        borderWeight: 1,
        strokeColor: "#3366FF",
        strokeOpacity: 1,
        strokeWeight: 1,
        // 线样式还支持 'dashed'
        strokeStyle: "solid",
        // strokeStyle是dashed时有效
        strokeDasharray: [10, 10],
        lineJoin: 'round',
        lineCap: 'round',
        zIndex: 50,
      })

      bezierCurve.setMap(map)
      // 缩放地图到合适的视野级别
      map.setFitView([ bezierCurve ])


      this.pointsList.forEach((item) => {
        console.log(item,'pItem')
        // console.log(item.title, "挑起");
        var marker = new AMap.Marker({
          // position: item.coordinates.split(",") ,
          position: item.lnglat ,
          icon: "//townassets.zhishang2017.com/pic/hongdian.png",
          anchor: "bottom-center",
          offset: new AMap.Pixel(0,0),
        });
        marker.setMap(map);
        // 设置鼠标划过点标记显示的文字提示
        marker.setTitle(`${item.name}`);

        // 设置label标签
        // label默认蓝框白底左上角显示，样式className为：amap-marker-label
        marker.setLabel({
          direction: "top",
          offset: new AMap.Pixel(0, 0), //设置文本标注偏移量
          content: `<div class='info'>${item.name}</div>`, //设置文本标注内容
        });
        let that = this;
        marker.on("click", function (e) {
          if (item.pointType==1){
            that.$router.push(`/housing?id=${item.manageId}`);
          }else {
            that.$router.push(`/housingAddInfo?editId=${item.relevantId}&&id=${item.manageId}`);
          }

          // that.pointsList.some((item) => {
          //   console.log(e,'撒3')
          //   that.$router.push('/houseAdd?id='+ item.id);
          //   if (item.lnglat[0]==e.lnglat.lng){
          //     console.log(e,'撒2')
          //      that.$router.push('/houseAdd?id='+ item.id);
          //   }
          //
          // })
          console.log(e,'撒')
          //覆盖物点击事件
        });
      });
    },
    // 纯文本标注
    setMapText() {
      let self = this;
      // 创建 AMap.Text 实例
      let makerList = [];
      this.pointsList.forEach((item) => {
        item.style = {
          padding: ".75rem 1rem",
          "margin-bottom": "1rem",
          "border-radius": ".25rem",
          "background-color": "#6dd400",
          width: "15rem",
          "border-width": 0,
          "text-align": "center",
          "font-size": "20px",
          color: "#ffffff",
        };
        // text/position/style 高德必须
        var text = new AMap.Text({
          text: item.name,
          position: item.lnglat,
          style: item.style,
          row: item, //对象所有数据
        });
        // 移入移除避免遮盖
        text.on("mouseover", function (e) {
          e.target.setTop(true);
        });
        text.on("mouseout", function (e) {
          e.target.setTop(false);
        });
        // 点击内容获取
        text.on("click", self.handlerMapClick);
        makerList.push(text);
      });
      this.map.add(makerList);
      // 自动适应显示想显示的范围区域
      this.map.setFitView();
    },

    handlerMapClick(e) {
      // console.log("handlerMapClick:", e.target.De.row);
      this.$router.push({
        path: "/custom/premises/premises",
        query: {
          id: e.target.De.row.id,
        },
      });
    },

    // 第一张图
    getEchartZhu() {
      let zhu = this.$echarts.init(document.getElementById("chartZhu"));
      //var myChart = echarts.init(chartDom);
      var option;
      var arr = this.house.areaList;
      option = {
        legend:{
          data:[
            {name:'平方米',textStyle:{color:'#ffffff'}
          },
          ],
        },
        tooltip: {},
        dataset: {
          source: arr,
        },
        xAxis: {type: 'category',
          axisLabel:{
            show:true,
            textStyle:{
              color:'#ffffff'
            },
            interval:false,
          }},
        yAxis: {
          axisLabel:{
            show:true,
            textStyle:{
              color:'#ffffff'
            }
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "12%",
          width: "92%",
          height: "60%",
          containLabel: true
        },

        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [{type: 'bar', barWidth: '20%',}],
        color: ['#12E5F3'],

      };
      zhu.setOption(option);
    },
    getEchartZu() {
      let zu = this.$echarts.init(document.getElementById("chartZu"));
      //var myChart = echarts.init(chartDom);
      var option;
      var arr = this.houseInfo.area;
      option = {
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: ['村镇', '公司单位', '居委', '行政事业单位国有资产'],
          axisLabel:{
            show:true,
            textStyle:{
             color:'#ffffff'
            },
            interval: 0
          }
        },
        yAxis: {
          type: 'value',
          axisLabel:{
            show:true,
            textStyle:{
              color:'#ffffff'
            }
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "12%",
          width: "92%",
          height: "80%",
          containLabel: true
        },

        series: [
          {
            name: '面积/m²',
            type: 'line',
            stack: 'Total',
            data: arr
          },
        ],
        color: ['#12E5F3'],
      };
      zu.setOption(option);
    },
    getEchartCun() {
      let cun = this.$echarts.init(document.getElementById("chartCun"));
      //var myChart = echarts.init(chartDom);
      var option;
      var arr = this.houseInfo.yearRents;
      option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          type: 'scroll',
          orient: 'vertical',
          right: 30,
          top: 40,
          bottom: 25,
          data:[{
            name:'村级',textStyle:{color:'#ffffff'}
          },
            {name:'公司单位',textStyle:{color:'#ffffff'}
            },
            {name:'居委',textStyle:{color:'#ffffff'}
            },
            {name:'行政事业单位国有资产',textStyle:{color:'#ffffff'}
            },
          ],
        },
        series: [
          {
            name: '房屋租赁使用情况(年租金/万)',
            type: 'pie',
            radius: ['40%', '70%'],
            center: ['35%', '50%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },

            labelLine: {
              show: false
            },
            data: arr
          }
        ],
        color: [ '#12E5F3','#0A89FC','#09E281','#FFD04B']
      };
      cun.setOption(option);
    },
    getEchartTu() {
      let tu = this.$echarts.init(document.getElementById("chartTu"));
      //var myChart = echarts.init(chartDom);
      var option;
      var arr = this.land.histogramList;
      console.log(arr,'Error in mounted hook: "TypeError: data.count is not a function"')
      option = {
        legend:{
          data:[
            {name:'镇保土地',textStyle:{color:'#ffffff'}
            },

          ],
        },
        tooltip: {},
        dataset: {
          source: arr,
        },

        xAxis: {type: 'category',
          boundaryGap:true,
          axisLabel:{
            show:true,
            textStyle:{
              color:'#ffffff'
            },
            interval: 0
          },
          },
        yAxis: {
          axisLabel:{
            show:true,
            textStyle:{
              color:'#ffffff'
            },
          }
        },
        grid: {
          left: "0%",
          right: "4%",
          bottom: "12%",
          width: "100%",
          height: "60%",
          containLabel: true
        },

        series: [{type: 'bar', barWidth: '20%',}],
        color: ['#12E5F3'],

      };
      tu.setOption(option);
    },
    getEchartTuZu() {
      let tuzu = this.$echarts.init(document.getElementById("chartTuZu"));
      //var myChart = echarts.init(chartDom);
      var option;
      var arr = this.landInfo.area;
      option = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: "0%",
          right: "4%",
          bottom: "12%",
          width: "96%",
          height: "80%",
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['光明','光辉', '三灶','宣桥', '新安', '季桥','长春','中心', '项埭','腰路','陆桥','张家桥','公司'],
          axisLabel:{
            show:true,
            textStyle:{
              color:'#ffffff'
            },
            interval:false,
          }
        },
        yAxis: {
          type: 'value',
          axisLabel:{
            show:true,
            textStyle:{
              color:'#ffffff'
            },
            interval:false,
          }
        },
        series: [
          {
            name: '面积/亩',
            type: 'line',
            stack: 'Total',
            data: arr
          },

        ],
        color: ['#12E5F3']
      };
      tuzu.setOption(option);
    },
    getEchartTuZuMoney() {
      let TuZuMoney = this.$echarts.init(document.getElementById("chartTuZuMoney"));
      var option;
      var arr = this.landInfo.yearRents;
      option = {
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: '土地年租金(年租金 万元/年)',
            type: 'pie',
            radius: ['40%', '70%'],
            center: ['60%', '40%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },

            labelLine: {
              show: false
            },
            data: arr
          }
        ],
        // color: ['#AD72FF', '#12E5F3','#FF409E','#09E281'],
        color: ['#12E5F3','#0A89FC','#09E281','#FFD04B','#2B1B84','#3A00E5','#652D91','#FF409E','#FF884D','#AD72FF','#9CB3FF','#FF1A19','#A2FF2D']
      };
      TuZuMoney.setOption(option);
    },
    getHouse(){
      let that = '';
      getIndexHouse(that).then((res) => {
         this.house = res.data;
        this.pointsList = res.data.mapPoints || [];
        console.log(this.house,'house')
      });
    },
    getLand(){
      getIndexLand().then((res) => {
         this.land = res.data;
        console.log(this.land,'land')
      });
    },
    getHouseInfo(){
      getIndexHouseInfo().then((res) => {
        this.houseInfo = res.data;
        this.leftAmt = res.data.totalYearRent;
        console.log(this.houseInfo,'houseInfo')
      });
    },
    getLandInfo(){
      getIndexLandInfo().then((res) => {
        this.landInfo = res.data;
        this.rightAmt = res.data.totalYearRent;
        console.log(this.landInfo,'landInfo')
      });
    },
    searchPoint() {
      getIndexHouse(this.searchName).then((res) => {
        this.house = res.data;
         this.pointsList = res.data.mapPoints || [];
        console.log(this.house,'house')
        this.initMap();
      });
    }




  },
  mounted() {
    this.windowHeight = window.screen.height;
    this.windowWidth = window.screen.width;
    this.getHouse();
    this.getLand();
    this.getHouseInfo();
    this.getLandInfo();

    setTimeout(() => {
      this.getEchartZhu();
      this.getEchartZu();
      this.getEchartCun();
      this.getEchartTu();
      this.getEchartTuZu();
      this.getEchartTuZuMoney();
      this.initMap();
    }, 900);

    let data = localStorage.getItem("permissions");

    if (data.includes("*")){
      this.superuser = true;
    }
    if (data.includes("房屋管理")){
      this.ishouse = true;
    }
    if (data.includes("土地信息")){
      this.island = true;
    }



  },
};
</script>

<style lang="scss" scoped>
.left-title {
  color: #17E0FE;
  font-size: calc(100vw * 16 / 1920);
  //padding: 0.5vh 0 0 3.5vw;
  text-align: center;
  padding-top: 1vh;
}

.right-title {
  color: #17E0FE;
  font-size: calc(100vw * 16 / 1920);
  //padding: 0.5vh 0 0 3.5vw;
  text-align: center;
  padding-top: 1vh;
}

table {
  padding: 3vh 2vw 0 3.8vw;
  box-sizing: border-box;
  width: 100%;
  height: 23vh;
  color: #fff;
  font-size: calc(100vw * 18 / 1920);
  text-align: center;
}

.container::v-deep {
  width: 100%;
  position: relative;

  .allmap {
    width: 100%;
    height: 100%;
  }

  .left-container {
    position: absolute;
    left: 0;
    top: 0.4vh;
    width: 23vw;
    height: 100%;

    .one-container,
    .two-container {
      position: absolute;
      left: 1.5vw;
      width: 100%;
      //margin-bottom: 1.8vh;
    }

    .one-container {
      background-color: #000001 !important;
      background: url(@/assets/use/box1.png);
      background-size: 100% 100%;
      height: 20vh;
      cursor: pointer;
    }

    .two-container {
      background-color: #000001 !important;
      top: 21vh;
      background: url(@/assets/use/box1.png);
      background-size: 100% 100%;
      height: 22vh;
      cursor: pointer;
    }

    .three-container {
      background-color: #000001 !important;
      position: absolute;
      top: 44vh;
      left: 1.5vw;
      background: url(@/assets/use/box1.png);
      background-size: 100% 100%;
      width: 100%;
      height: 22vh;
      cursor: pointer;
    }

    .four-container {
      background-color: #000001 !important;
      position: absolute;
      top: 67vh;
      left: 1.5vw;
      background: url(@/assets/use/box1.png);
      background-size: 100% 100%;
      width: 100%;
      height: 22vh;
      cursor: pointer;
    }
  }

  .right-container {
    position: absolute;
    top: 0.4vh;
    right: 0;
    width: 23vw;
    height: 100%;

    .four-container,
    .five-container,
    .six-container,
    .seven-container{
      position: absolute;
      right: 1.5vw;
      width: 100%;
      cursor: pointer;
    }

    .four-container {
      background-color: #000001 !important;
      height: 20vh;
      background: url(@/assets/use/box1.png);
      background-size: 100% 100%;
      cursor: pointer;
      cursor: pointer;
    }
    .five-container {
      background-color: #000001 !important;
      top: 21vh;
      height: 22vh;
      background: url(@/assets/use/box1.png);
      background-size: 100% 100%;
      cursor: pointer;
    }

    .six-container {
      background-color: #000001 !important;
      top: 44vh;
      height: 22vh;
      background: url(@/assets/use/box1.png);
      background-size: 100% 100%;
      cursor: pointer;
    }
    .seven-container {
      background-color: #000001 !important;
      top: 67vh;
      height: 22vh;
      background: url(@/assets/use/box1.png);
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
}
</style>

<!-- 地图style -->
<style lang="scss" scoped>
.container_ {
  width: 100%;
  height: 94vh;
  position: absolute;
  top: 0;
  //background: url(@/assets/use/back.png);
  //background-size: contain;
}

::v-deep .amap-maptype-con,
::v-deep .amap-maptype-list,
::v-deep .amap-geolocation-con,
::v-deep .amap-logo,
::v-deep .amap-copyright {
  display: none !important;
}

::v-deep .amap-logo,
::v-deep .amap-copyright {
  display: none !important;
}
::v-deep .amap-marker-label{
  background-color: #94f1b7;

}

::v-deep .el-input__inner{
  height: 3vh;

}
</style>
