<template>
  <div>
    <div class="content">
      <div class="content-container">
        <div class=" flex-row justify-between" style="text-align: center;padding-top: 3vh;
    padding-bottom: 2vh;">
          <img
              src="..//assets/use/login_title.png"
              style="width: 15vw"
          />
        </div>

        <div class="box_3 flex-row justify-between">
          <span class="text_1" v-bind:class="{ choose_login: pass }" @click="chooseLogin(1)">账号密码登录</span>
<!--          <span class="text_2" v-bind:class="{ choose_login: !pass }" @click="chooseLogin(2)">验证码登录</span>-->
        </div>
        <div v-if="openPsd">
        <el-input
            v-model="info.username"
            placeholder="请输入账号"
            style="width: 100%"
        ></el-input>
          <el-input
              type="password"
              v-model="info.password"
              placeholder="请输入密码"
              style="width: 100%; positiion: absolute; left: 0;bakcground:transparent"
          ></el-input>
        </div>
        <div v-if="!openPsd" >
          <el-input
              v-model="info.phonenumber"
              placeholder="请输入手机号"
              style="width: 100%"
          ></el-input>
          <div >
          <el-input
              type="text"
              v-model="info.smsCode"
              placeholder="请输入验证码"
              style="width: 100%; positiion: absolute; left: 0;bakcground:transparent"
          ></el-input>
            <div style="position: relative;width: 10vw;top: -7.5vh; font-size: calc(0.833333vw); text-align: right;left: 10vw; font-size: calc(100vw * 16 / 1920);color: #0054FE;">
              <span v-if="countdown === 0" @click="getVerificationCode">获取验证码</span>
              <p v-if="countdown > 0">{{ countdown }}秒后重新发送</p>
            </div>
          </div>
        </div>


        <div class="login-btn" @click="login" @keyup.enter="keyDown(e)">
          登 录
        </div>
      </div>

    </div>
    <div  style="color: white;display: flex;justify-content: center;align-items: center;height: 10vh;text-decoration: none; text-align: center;font-size: 16px;margin-top: 10px;width: 100%;position: absolute;bottom: 0;">
      <img src="..//assets/images/jh.jpg"  style="width: 15px;margin-right: 10px;"/>
      <a href="https://beian.miit.gov.cn/"  style="color: white;text-decoration: none; text-align: center;font-size: 16px;" >沪公网安备31011402010697号</a>
      <a href="https://beian.miit.gov.cn/" style="margin-left: 10px;color: white;text-decoration: none; text-align: center;font-size: 16px;">沪ICP备2024042779号-1</a>
<!--      <a href="https://beian.miit.gov.cn/" style="color: white;text-decoration: none; text-align: center;font-size: 16px;margin-top: 10px;width: 100%;position: absolute;bottom: 0;">沪ICP备2024042779号-1</a>-->

    </div>
     </div>
</template>

<script>
import {getCode, getLogin, getUserInfo} from "@/api/index.js";
import { getSmsCode, getSmsLogin } from "@/api/Hosing.js";
export default {
  name: "login",
  data() {
    return {
      info: {
        username: "", // 账号
        password: "", // 密码
        code: "", // 验证码
        phonenumber: "", // 验证码
        uuid: "",
      },
      pass:true,
      code:false,
      img: "",
      checked: false,
      openPsd: true, // 是否显示密码
      countdown: 0,
    };
  },
  methods: {
    getUser() {
      getUserInfo().then((res) => {
        localStorage.setItem("userInfo",  res.data.roles.join(","));
        localStorage.setItem("permissions",  res.data.permissions);
        console.log(res.data,'user1111111111')
        if (res.emp) {
          this.info.city = res.emp.etProvince;
          this.title = res.user.logoName;
          this.logo = res.user.logoVar;
        } else {
          this.info.city = "";
        }
      });
    },
    // 按回车登录
    keyDown(e) {
      // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode == 13 || e.keyCode == 100) {
        this.login(); // 定义的登录方法
      }
    },
    //切换登录方式
    chooseLogin(e){
      if(e==1){
        this.pass =true;
        this.openPsd = true;
      }else {
        this.pass =false;
        this.openPsd = false;
      }

    },
    getVerificationCode() {
      console.log(1111)
      if (this.info.phonenumber != "" ) {
        getSmsCode(this.info).then((res) => {
          if (res.code == 200) {

            this.$message({
              showClose: false,
              message: "发送成功",
              type: "success",
            });
          }
        });
        this.countdown = 60; // 倒计时60秒
        const timer = setInterval(() => {
          this.countdown--;
          if (this.countdown === 0) {
            clearInterval(timer);
          }
        }, 1000);
      }else {
        this.$alert("请填写手机号", "提示", {
          confirmButtonText: "确定",
          showClose: false,
        });
      }
    },

    // 登录
    login() {
     if ( this.pass){
       if (this.info.username != "" &&
           this.info.password != "") {
         getLogin(this.info).then((res) => {
           if (res.code == 200) {
             localStorage.setItem("token", "Bearer " + res.data.token);
             this.$message({
               showClose: false,
               message: "登录成功",
               type: "success",
             });
             getUserInfo().then((res) => {
               localStorage.setItem("userInfo",  res.data.roles.join(","));
               localStorage.setItem("permissions",  res.data.permissions);
               if (res.emp) {
                 this.info.city = res.emp.etProvince;
                 this.title = res.user.logoName;
                 this.logo = res.user.logoVar;
               } else {
                 this.info.city = "";
               }
             });
             setTimeout(() => {
               this.$router.push("/index");
             }, 1000);
           }
         });


       } else {
         this.$alert("请填写完整", "提示", {
           confirmButtonText: "确定",
           showClose: false,
         });
       }
     }else {
       if (this.info.phonenumber != "" &&
           this.info.smsCode != ""&&
           this.info.phonenumber.length==11) {
         getSmsLogin(this.info).then((res) => {
           if (res.code == 200) {
             localStorage.setItem("token", "Bearer " + res.data.token);
             this.$message({
               showClose: false,
               message: "登录成功",
               type: "success",
             });
             getUserInfo().then((res) => {
               localStorage.setItem("userInfo",  res.data.roles.join(","));
               localStorage.setItem("permissions",  res.data.permissions);
               if (res.emp) {
                 this.info.city = res.emp.etProvince;
                 this.title = res.user.logoName;
                 this.logo = res.user.logoVar;
               } else {
                 this.info.city = "";
               }
             });
             setTimeout(() => {
               this.$router.push("/index");
             }, 1000);
           }
         });
       }
       // else {
       //   this.$alert("请校验输入格式", "提示", {
       //     confirmButtonText: "确定",
       //     showClose: false,
       //   });
       // }
     }
    },
  },
  created() {
    localStorage.removeItem("token");
  },
  mounted() {
    this.info.username = sessionStorage.getItem("userName");
    this.info.password = sessionStorage.getItem("password");
    if (sessionStorage.getItem("checked") != undefined) {
      this.checked = true;
    }
    // 绑定监听事件
    window.addEventListener("keydown", this.keyDown);
  },
  destroyed() {
    // 销毁事件
    window.removeEventListener("keydown", this.keyDown, false);
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 7.4vh;
  background: #0c3688;
  position: relative;
  line-height: 7.4vh;
  img {
    position: absolute;
    left: 3vw;
    top: 1.8vh;
    width: 2.8vw;
    height: 4vh;
  }
  span {
    margin-left: 6.5vw;
    //color: #fff;
    font-size: calc(100vw * 20 / 1920);
  }
  .header-title {
    //color: #fff;
    font-size: calc(100vw * 20 / 1920);
    text-align: center;
  }
}
.content::v-deep {
  width: 100%;
  //height: calc(100vh - 7.4vh);
  height:100vh;
  background: url(@/assets/use/loginBack.png);
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .content-container {
    //background: url(@/assets/images/loginBg.png);
    background-size: 100% 100%;
    background-color: #fff;
    border-radius: 8px;
    width: 28vw;
    height: 60vh;
    //color: #fff;
    padding: 3.5vh 4vw;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: calc(100vw * 22 / 1920);
      margin-bottom: 3vh;
    }
    .img {
      width: 6vw;
      height: 4.6vh;
    }
    .el-input__inner {
      height: 6vh;
      font-size: calc(100vw * 16 / 1920);
      //box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
      //border: 0.02rem solid #286ae8;
      background: #EFEEEE;

      border-radius: 10px;
      //color: #fff;
      margin-bottom: 3.5vh;
      //padding-bottom: 2vh;
      &::placeholder {
        //color: #fff;
      }
    }
    .el-checkbox {
      color: #fff;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .el-checkbox__input {
        height: 14px;
        .el-checkbox__inner {
          border: 1px solid #00c0ff;
          background: none;
        }
      }
      .el-checkbox__label {
        font-size: calc(100vw * 15 / 1920);
      }
    }
    .login-btn {
      // background: linear-gradient(90deg, #FFFF00 6%, #FFA500 25%, #F14444 45%, #D53567 55%, #9A109A 94%);
      background: #0054FE;
      height: 6vh;
      border-radius: 2vw;
      text-align: center;
      line-height: 6vh;
      color: #FFFFFF;
      cursor: pointer;
      margin-top: 1vh;
    }
  }
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #fff;
}
::v-deep input:-webkit-autofill ,::v-deep textarea:-webkit-autofill,::v-deep select:-webkit-autofill {

  -webkit-text-fill-color: #ededed !important;

  -webkit-box-shadow: 0 0 0px 1000px transparent  inset !important;

  background-color:transparent;

  background-image: none;

  transition: background-color 50000s ease-in-out 0s; //背景色透明  生效时长  过渡效果  启用时延迟的时间

}


input:-webkit-autofill , textarea:-webkit-autofill, select:-webkit-autofill {
  // 字体颜色
  -webkit-text-fill-color: #fff !important;
  // 背景颜色
  background-color:transparent !important;
  // 背景图片
  background-image: none !important;
  //设置input输入框的背景颜色为透明色
  -webkit-box-shadow: 0 0 0px 1000px transparent  inset !important;
  transition: background-color 50000s ease-in-out 0s;
}

.box_2 {
  position: relative;
  width: 227px;
  height: 29px;
  margin: 50px 0 0 65px;
}

.box_3 {
  position: relative;

  /* height: 30px; */
  /* margin: 0 0 0 65px; */
  padding-bottom: 4vh;
  padding-top: 2vh;
  display: flex;
  justify-content: space-between;
  width: 60%;
}

.text_1 {

  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: calc(100vw * 16 / 1920);
  letter-spacing: 0.20000000298023224px;
  font-family: AlibabaPuHuiTi-Regular;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  line-height: 22px;
  margin-top: 3px;
}

.text_2 {

  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: calc(100vw * 16 / 1920);
  letter-spacing: 0.25px;
  font-family: AlibabaPuHuiTiM;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  line-height: 27px;

}
.choose_login{
  border-bottom: 5px solid #0054FE;
  color: rgba(65, 65, 65, 1);
  font-size: calc(100vw * 18 / 1920);
}

.block_1 {
  background-color: rgba(0, 84, 254, 1);
  position: absolute;
  left: 127px;
  top: 25px;
  width: 75px;
  height: 4px;
}
</style>
