<template>
  <div>
    <div class="add"  @click="goAdd" v-if="isAdmin=='admin'">新增</div>

    <div class="query light" style="width: 70px;text-align: center;" @click="exportList()">导出</div>

    <div style="padding-bottom: 2vh;"></div>
      <el-table
          :data="totalList"
          :header-cell-style="headerStyle"
          :cell-style="rowStyle"
          :tree-props="{children: 'subList', hasChildren:'true' }"
          row-key="id"
      >
        <el-table-column prop="parentIndex" label="序号"  width=60 ></el-table-column>
        <el-table-column prop="place" label=处数></el-table-column>
        <el-table-column prop="affiliatedUnit" label=所属单位></el-table-column>
        <el-table-column prop="address" label=地理位置></el-table-column>
        <el-table-column prop="getChargeForUse" label=是否收取土地使用费></el-table-column>
        <el-table-column prop="amount" label=面积（亩）></el-table-column>
        <el-table-column label="操作" width="140">
          <template slot-scope="scope">
            <div v-if="scope.row.manageId">
              <el-button size="mini" type="text" v-if="isAdmin=='admin'" @click="edit(scope.row.id)">编辑</el-button>
              <el-button size="mini" type="text" v-if="isAdmin=='admin'"  @click="del(scope.row.id)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[15]"
          :page-size="100"
          layout="sizes, prev, pager, next , total"
          :total="total"
      >
      </el-pagination>


    </div>


</template>

<script>
import {
  getCunZhuanDel,
  getCunZhuanTotal,
  getCunZhuanTotalExport,
  getHousingXian,
  getHousingZuExport
} from "@/api/Hosing";
export default {
  name: "userJurisdiction",
  props:{
    rowId:String,
    isAdmin:String,
  },
  data() {
    return {
      form: {
        nickName: "",
        userName: "", // 手机号
        etName:'', // 企业
      },
      totalList: [],
      currentPage: 1,
      pageSize: 15,
      total: 0,

    };
  },

  methods: {
    goAdd(){
      this.$router.push({ path: '/landZhuanAdd', query: { id: this.rowId}});
    },
    goIndex(){
      this.$router.push("/index");
    },
    del(row){
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        getCunZhuanDel(row).then((res) => {
          if (res.code === 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
          this.getList();
        });
      });
    },
    edit(row){
      this.$router.push({ path: '/landZhuanAdd', query: { editId: row,id: this.rowId}});
    },
    headerStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    rowStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getList();
    },
    getList() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        manageId: this.rowId,
      };
      getCunZhuanTotal(data).then((res) => {
        this.totalList = res.data;
        this.totalList.forEach((item,index) =>{
          item.parentIndex = index+1;
          if (item.subList){
            item.subList.forEach((it,ind) =>{
              it.parentIndex = item.parentIndex+ '.'+ (ind+1)
            })
          }

        })
        this.total = res.total;
      });
    },
    exportList(){
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        manageId: this.rowId,
        rentOutType:1

      };
      getCunZhuanTotalExport(data).then((res) => {
        console.log(res,'res')
        console.log("ImageDownload`" +res.msg +"`" );
        this.$message({
          showClose: false,
          message: "导出成功",
          type: "success",
        });
      });
    },


  },
  mounted() {
      this.getList();


  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  margin-top: 2vh;
  width: 100%;
  position: relative;
  .left-title {
    position: absolute;
    left: 0;
    top: 0;
    background: url(@/assets/images/titleBg.png);
    background-size: 100% 100%;
    width: 11.1vw;
    height: 5.5vh;
    color: #fff;
    font-size: calc(100vw * 20 / 1920);
    text-align: center;
    line-height: 5.5vh;
    margin-right: 4vw;
  }
  .right-container {
    width: 81vw;
    position: absolute;
    left: 3vw;
    right: 0;
    margin: 0 auto;

    .right-top {

      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .el-form-item {
        display: flex;
        align-items: center;
        height: 4.6vh;

        .el-input__inner {
          width: 100%;
          height: 4.6vh;
          font-size: calc(100vw * 16 / 1920);
          box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
          border-radius: 6px;
          border: 0.02rem solid #286ae8;
          background: none;
          color: #fff;
          &::placeholder {
            color: #fff;
          }
        }
        .el-form-item__label {
          width: 6.5vw;
          text-align: left;
          color: #fff;
          font-size: calc(100vw * 16 / 1920);
        }
      }
      .query,
      .reset {
        width: 10vw;
        height: 100%;
        background: url(@/assets/images/updown.png);
        background-size: 100% 100%;
        color: #02b5ff;
        font-size: calc(100vw * 16 / 1920);
        line-height: 4.6vh;
        text-align: center;
        cursor: pointer;
      }
      .query {
        margin-right: 3vw;
      }
    }
  }
}
::v-deep .el-form {
  width: 65vw;
  padding-left: 10vw;
  box-sizing: border-box;
}
::v-deep .el-table {
  width: 99.9%;
  margin-bottom: 2.5vh;
  font-size: calc(100vw * 16 / 1920);
}
::v-deep .el-table__body {
  font-size: calc(100vw * 14 / 1920);
}
::v-deep .el-table__row {
  height: 4vh;
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  display: none;
}
::v-deep .el-pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1vh;
}
::v-deep .el-pagination .el-select .el-input .el-input__inner,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev,
::v-deep .el-pager li,
::v-deep .el-pager li.btn-quicknext,
::v-deep .el-pager li.btn-quickprev,
::v-deep .el-pagination__total {
  //background: #0f2455;
  border-radius: 6px;
  border: 1px solid #286AE8;
  box-shadow: inset 0px 0px 20px 21px rgba(15,53,116,0.6);
  color: #fff;
  text-align: center;
}
::v-deep .el-pager li,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  margin-right: 0.5vw;
}
::v-deep .el-pagination__total {
  margin-right: 0;
  padding: 0 0.8vw;
  box-sizing: border-box;
}
::v-deep .el-form-item__content {
  height: 4.6vh;
}
::v-deep .el-pager li.active {
  color: #409EFF;
}
::v-deep .el-icon-arrow-right{
  color: #FF884D;
}
.add{
  width: 10vw;
  height: 100%;
  background: url(@/assets/images/updown.png);
  background-size: 100% 100%;
  color: #02b5ff;
  font-size: calc(100vw * 16 / 1920);
  line-height: 4.6vh;
  text-align: center;
  cursor: pointer;
  margin-right: 3vw;


}

.light{
  background: url(@/assets/use/btn3.png) !important;
  background-size: 100% 100% !important;
  color: #2EE6FE !important;
  margin-top: 10px;
}
</style>
